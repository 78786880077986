.dolphin-sealion-container {
  @include left-arrow-header();
  @include top-image-section();
  .header-section {
    @include tablet-lg {
      display: none;
    }
  }
  h1 {
    margin-bottom: 5px;
  }

  .desktop-section-1 {
    @include tablet-lg {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .image-section {
    @include tablet-lg {
      width: 50%;
      height: auto;
    }
  }

  .section {
    padding: $padding;
  }

  .section-one {
    @include tablet-lg {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
    &::before {
      @include before();
      height: 45px;
      z-index: 9;
      top: 255px;
      border: none;
    }
    .description-18 {
      display: none;
      @include tablet {
        font-size: 20px;
      }
      @include tablet-lg {
        font-size: 24px;
        font-family: "G-Bold";
        display: block;
        margin-bottom: 20px;
      }
      @include desktop-sm {
        font-size: 28px;
      }
    }

    .description-14 {
      @include tablet {
        font-size: 16px;
        margin-top: 12px;
      }
      @include desktop-sm {
        font-size: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }

  .section-two {
    padding: 0;
    .text-section {
      @include responsive-h1-p-tag();
      @include margin-tabletlg-laptop;
      @include tablet-lg {
        margin-top: 70px;
        padding: 0;
        margin-bottom: 35px;
      }
    }
  }

  .desktop-left-section {
    .image {
      display: none;
      @include tablet {
        display: block;
        width: 200px;
        height: 200px;
        border-radius: 20px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }
      }

      @include tablet-lg {
        width: 341px;
        height: 266px;
      }
    }
  }

  .section-three {
    border-radius: 30px;
    background-color: $font-color-1;
    padding: 8px;
    padding-bottom: 24px;
    margin: 15px;
    box-sizing: border-box;
    margin-top: 30px;

    @include tablet {
      margin-bottom: 60px;
    }
    @include tablet-lg {
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
    @include tablet {
      border-radius: 20px;
      padding-bottom: 15px;
      @include flex();
    }
    .top-section {
      @include flex();
      .image {
        display: block;
        @include tablet {
          display: none;
        }
        img {
          width: 112px;
          height: 105px;
          @include object-fit(cover);
          border-radius: 30px;
        }
      }
      .details {
        @include flex-justify-direction(flex-start, column);
        padding-left: 15px;
        padding-top: 10px;
        flex: 1;
        @include tablet-lg {
          padding-left: 24px;
        }
        h1 {
          font-family: "G-Medium";
          margin-bottom: 0;
          text-shadow: none;
          @include tablet {
            font-size: 22px;
          }
        }
        p {
          @include tablet {
            font-size: 14px;
          }
          @include desktop-sm {
            font-size: 16px;
          }
        }
        p.span {
          font-family: "G-Bold";
          color: $font-color-2;
          @include tablet {
            font-size: 14px;
          }
          span {
            color: #e42535;
          }
        }
      }
    }

    .bottom-section {
      margin-top: 16px;
      @include tablet {
        padding-left: 15px;
        @include flex-justify(space-between);
        flex-direction: column;
        min-height: 47%;
        .button-container {
          text-align: left;
          button {
            font-size: 14px;
            @include tablet-lg {
              font-size: 16px;
            }
          }
        }
      }
      @include tablet-lg {
        padding-left: 24px;
      }
      p {
        @include tablet {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  .section-four {
    @include margin-tabletlg-laptop;
    @include responsive-h1-tag;
    @include tablet-lg {
      padding: 0;
    }
    h1 {
      margin-bottom: 20px;
      @include tablet-lg {
        margin-bottom: 30px;
      }
    }
  }

  .section-five {
    @include margin-tabletlg-laptop;
    @include responsive-h1-p-tag;
    @include tablet-lg {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }

  .section-six {
    @include margin-tabletlg-laptop;
    @include tablet-lg {
      @include flex-align(center);
    }
    .head-section {
      @include tablet-lg {
        @include flex-value(1);
      }
    }
    h1 {
      margin-bottom: 15px;
      @include tablet-lg {
        font-size: 26px;
      }
      @include desktop-sm {
        font-size: 34px;
      }
    }
    p {
      @include tablet-lg {
        font-size: 20px;
      }
    }
    .button-container {
      @include tablet-lg {
        @include flex-value(1);
        text-align: right;
      }
    }
    .more-button-white {
      margin-top: 18px;
    }
  }
}
