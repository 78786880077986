.card-carousel-container {
  border-radius: 20px;
  background-color: #ffffff;
  overflow: hidden;

  position: relative;
  @include tablet {
    padding-bottom: 25px;
  }
  @include tablet-lg {
    border-radius: 60px;
  }
  .custom-slider {
    height: 157px;
    // overflow: hidden;
    @include tablet {
      height: 300px;
    }
    @include tablet-lg {
      height: 400px;
    }
    @include laptop {
      height: 460px;
    }
    @include desktop {
      height: 500px;
    }
  }
  .slick-list {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
  }

  .parent-carousel {
    position: relative;
    cursor: pointer;
  }

  .video {
    width: 100%;
    height: 157px;
    @include object-fit(cover);
    border-radius: 20px;
    @include tablet {
      height: 300px;
    }
    @include tablet-lg {
      height: 400px;
    }
    @include laptop {
      height: 460px;
    }
    @include desktop {
      height: 500px;
    }
  }

  .image {
    width: 100%;
    height: 157px;
    border-radius: 20px;
    @include tablet {
      height: 300px;
    }
    @include tablet-lg {
      height: 400px;
      @include object-fit(cover);
    }
    @include laptop {
      height: 460px;
    }
    @include desktop {
      height: 500px;
    }
  }

  .text-section {
    text-align: center;
    .description {
      margin-top: 15px;
      font-family: "G-Book";
      font-size: 12px;
      line-height: 1.63;
      letter-spacing: 0px;
      text-align: left;
      color: #737373;
      @include tablet {
        font-size: 16px;
        margin-top: 12px;
      }
      @include tablet-lg {
        font-size: 18px;
        margin-top: 12px;
      }
      @include desktop-sm {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }

  .button-container {
    .read-button {
      @include tablet {
        font-size: 16px;
      }
    }
  }

  @include slick-dots();
}
