.conservaion-container {
  .description{
    padding-bottom: 25px;
  }
  li.description{
    transition: 0.5s;
    font-size: 18px;
  }
  .section {
    padding: $padding;
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  .section-one {
    @include responsive-h1-tag();
    h1 {
      width: 253px;
      @include tablet {
        width: 100%;
      }
    }
  }

  .section-two {
    padding: 0;
    @include responsive-h1-p-tag();
    .text-section {
      @include tablet-lg {
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 15px;
      }
    }
  }

  .cta-section {
    .button-container {
      width: 365px;
      margin: 20px auto 12px auto;
    }
  }

  .section-three {
    margin-top: 25px;
    @include responsive-h1-p-tag();
    @include tablet-lg {
      margin-top: 0;
    }
    .list-group {
      margin-top: 36px;
      @include tablet {
        @include flex-justify(space-between);
      }
      .list-item {
        @include justify-align(flex-start, center);
        margin: 24px 0;
        p {
          @include tablet-lg {
            font-size: 16px;
          }
          @include laptop {
            font-size: 18px;
          }
        }
        .items {
          width: 40px;
          margin-left: 15px;
          margin-right: 40px;
          @include tablet {
            margin: 0;
          }
          img {
            width: 25px;
            height: 25px;
            @include object-fit(contain);
          }
        }
      }
    }
  }

  .section-four {
    @include responsive-h1-tag();
    .button-container {
      margin: auto;
      button {
        width: 365px;
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        @include tablet-lg {
          font-size: 18px;
        }
        @include laptop {
          font-size: 21px;
        }
      }
    }
  }

  .section-five {
    @include responsive-h1-tag();
    @include tablet-lg {
      margin-top: 30px;
    }
  }

  .section-six {
    @include responsive-h1-p-tag();
    margin-top: 15px;
    margin-bottom: 25px;
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
      margin-bottom: 80px;
    }
  }

  .section-eight {
    @include responsive-h1-tag();
    @include tablet-lg {
      margin: 30px 0 45px;
      .text-section {
        margin: 0 60px;
      }
    }

    .main-text {
      margin-bottom: 0;
    }
  }
}
