.association-banner {
    margin-top: 20px;
    @supports (justify-content: space-evenly) {
        @include justify-align(space-evenly, center);
    }

    @include justify-align(space-around, center);
    background-repeat: no-repeat;
    background-size: calc(100% + 225px) 100%;
    min-height: 147px;
    background-position-x: -84px;
    margin: auto;
    width: 100%;
    @include tablet-lg {
        min-height: 245px;
    }

    @include media-width-max(991) {
        // flex-wrap: wrap;
        padding: 35px 10px;
    }
    @include media-width-max(380) {
        flex-wrap: wrap;
    }

    p {
        width: 170px;
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: 0.01px;
        text-align: left;
        color: #000000;
        position: relative;
        &::before {
            @include line() {
                left: -25px;
            }

            @include tablet-lg {
                height: 150%;
                left: -34px;
                top: 50%;
                transform: translateY(-50%);
            }
            @include media-width-max(380) {
                display: none;
            }
        }

        @include tablet {
            width: 75%;
            font-size: 18px;
            margin: auto;
        }
        @include tablet-lg {
            width: 95%;
            font-size: 18px;
            margin: auto;
        }
        @include media-width-max(1200) {
            font-size: 16px;
        }
        @include media-width-max(991) {
            // width: 100%;
            margin-top: 15px;
        }
        @include media-width-max(767) {
            width: 75%;
            margin-top: 15px;
            margin: auto;
        }
        @include media-width-max(380) {
            margin-top: 15px;
        }
    }
    .button-container {
        position: relative;
        top: 12px;
        a {
            @include tablet-lg {
                font-size: 16px;
            }
        }
    }
    .image-section {
        @include tablet {
            flex: 100%;
            justify-content: center;
        }
        @include media-width-min(580) {
            display: flex;
        }
    }
    .image {
        margin: 0 15px;

        @include object-fit(cover);

        @include tablet-lg {
            width: 124px;
        }
        @include media-width-max(1200) {
            width: 90px;
        }
    }
}
