.venues-container {
  .section {
    padding: $padding;
    @include tablet-lg {
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 80px;
    }
    @include laptop {
      padding-left: 60px;
      padding-right: 60px;
    }

    h1 {
      @include tablet {
        font-size: 34px;
        margin-bottom: 25px;
      }
      @include laptop {
        font-size: 38px;
      }
    }
  }

  .line {
    margin: 24px 0;
    background-color: $font-color-1;
    width: 100%;
    height: 1px;
  }

  .bottom-section {
    padding: $padding;
    .custom-button {
      margin-top: 24px;
      @include justify-align(center, center);
    }
  }
}
