.square-carousel-container {
  // padding-left: 15px;
  // @include tablet-lg {
  //   margin-bottom: 60px;
  // }
  position: relative;
  z-index: 9;

  .slick-slide {
    margin-right: 20px;
  }

  .slick-list {
    padding-left: 16px !important;
    @include tablet-lg {
      margin-bottom: 60px;
    }
  }

  .slick-track {
    @include flex();

    @include tablet-lg {
      padding-left: 30px;
    }
    @include laptop {
      padding-left: 60px;
    }
  }

  .parent {
    // min-width: 339px !important;
    height: 189px;
    border: 3px solid white;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    // margin-left: 15px;
    @include tablet {
      border-radius: 50px;
      height: 265px;
    }
    @include tablet-lg {
      height: 375px;
    }
    @include laptop {
      height: 514px;
    }
    @include desktop {
      height: 565px;
    }
    @include desktop-lg {
      height: 620px;
    }
  }

  .description {
    position: absolute;
    bottom: 10px;
    left: 19px;
    @include media-width-max(370) {
      left: 14px;
    }
    @include tablet {
      bottom: 20px;
      left: 35px;
    }
    @include tablet-lg {
      bottom: 46px;
      left: 40px;
    }
    .para1 {
      margin-bottom: 10px;
      font-family: "G-Medium";
      font-size: 18px;
      line-height: 0.2;
      letter-spacing: normal;
      text-align: left;
      color: $font-color-1;
      @include media-width-max(370) {
        font-size: 16px;
      }
      @include tablet {
        font-size: 28px;
      }
      @include tablet-lg {
        font-size: 40px;
      }
      @include laptop {
        font-size: 50px;
        margin-bottom: 40px;
      }
    }

    .para2 {
      font-family: "G-Book";
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0px;
      text-align: left;
      color: $font-color-1;
      @include tablet {
        font-size: 16px;
        margin-top: 12px;
      }
      @include tablet-lg {
        font-size: 22px;
        margin-top: 12px;
      }
      @include laptop {
        // font-size: 25px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }

  img {
    margin: auto;
    width: 100%;
    height: 100%;
    // @include object-fit(cover);
  }

  @include slick-dots($indicator-color-1);
}
