.tv-carousel-container {
  padding-left: 16px;

  .slick-slide {
    width: 132px !important;
    height: 180px;
    padding-right: 25px;
    position: relative;
    margin-right: 18px;
    @include tablet-lg {
      width: 277px !important;
      padding-right: 0;
    }
  }

  .frame {
    position: absolute;
    top: -6px;
    left: 0px;
  }

  .slick-track {
    @include flex();
    @include tablet-lg {
      height: 250px;
    }
  }

  .slick-list {
    padding-top: 15px;
  }

  .image {
    width: 64px;
    height: 96px;
    border-radius: 0;
    border-top-right-radius: 30px;
    position: relative;
    left: 3px;
    top: 3px;
    @include object-fit(cover);
    z-index: 1;
    margin: auto;
    left: 50%;
    transform: translateX(-64%);
    @include tablet-lg {
      margin: 0;
      transform: translateX(-50%);
      width: 96px;
      top: 35px;
    }
  }
  p {
    width: 117px;
    position: absolute;
    left: 55%;
    transform: translateX(-50%);
    font-family: "G-Medium";
    font-size: 13px;
    line-height: 1.36;
    letter-spacing: 0px;
    color: black;
    margin-top: 15px;
    @include tablet-lg {
      font-size: 16px;
      width: 100%;
      text-align: center;
      margin-top: 70px;
      left: 50%;
    }
  }

  @include slick-dots($indicator-color-1);
}

// .frame-carousel-container {
//     //   .slick-list {
//     //     padding: 0 20% 0 0 !important;
//     //   }

//     .slick-slide {
//       padding-right: 25px;
//       position: relative !important;
//     }

//     .frame {
//       position: absolute;
//       top: -6px;
//       left: -20px;
//     }

//     .slick-list {
//       padding-top: 10px;
//     }

//     .image {
//       width: 173px !important;
//       height: 118px !important;
//       border-radius: 54%;
//       border-top-right-radius: 97px;
//       border-bottom-right-radius: 104px;
//     }
//   }

.tv-carousel-2-container {
  @extend .tv-carousel-container;
  padding-left: 0;
  .slick-slide {
    padding-right: 0;
    margin-right: 32px;
    @include tablet-lg {
      width: 132px !important;
      width: 252px !important;
      margin-right: 75px;
      margin-right: 65px;
    }
  }

  .slick-list {
    padding-left: 16px;
    @include laptop {
      padding-left: 50px;
    }
  }

  .image {
    width: 100%;
    top: 2px;
    z-index: -1;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    left: 3px;
    transform: none;
    @include tablet-lg {
      border-top-left-radius: 66px;
      height: 190px;
    }
  }

  .frame {
    @include tablet-lg {
      width: 275px;
    }
  }
  p {
    margin-top: 8px;
  }
  .para1 {
    text-align: center;
    @include tablet-lg {
      transform: translateX(-50%);
      margin-top: 32px;
      margin-left: 15px;
      font-size: 18px;
    }
  }
  .para2 {
    top: 115px;
    text-align: center;
    color: rgba(15, 38, 96, 0.6);
    @include tablet-lg {
      top: 248px;
      margin-left: 14px;
      font-size: 15px;
    }
  }

  .carousel-parent {
    position: relative;
    left: -51px;
  }

  .slick-track {
    @include flex();
    @include tablet-lg {
      // padding-left: 50px;
      height: 382px;
    }
  }
}
