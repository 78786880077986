.map-container {
    @include left-arrow-header() {
        .header-section {
            top: 0;
            height: 59px;
            background-color: $header-color-2;
            @include tablet-lg {
                display: none;
            }
            img {
                position: absolute;
            }
            .list {
                left: unset;
                right: 15px;
            }
        }
    }
    .section {
        padding: $padding;
    }

    // Map Container
    .map-layout {
        width: 100%;
        overflow-x: scroll;
        svg {
            @include media-width-min(919) {
                width: 100% !important;
            }
        }
        text {
            cursor: pointer;
        }
    }
}
