.button-container {
  text-align: center;
  button {
    text-transform: capitalize;
  }
  .normal-button {
    margin-top: 24px;
    width: calc(100% - 35px);
    height: 50px;
    font-family: "G-Book";
    font-size: 16px;
    // line-height: 2.56;
    line-height: 12px;
    letter-spacing: 0.01px;
    text-align: center;
    color: #0f2660;
    border: none;
    background-image: -o-linear-gradient(9deg, #fbe356 4%, #ff8875 71%);
    background-image: linear-gradient(81deg, #fbe356 4%, #ff8875 71%);
    cursor: pointer;
    border-radius: 30px;

    .image {
      display: none;
    }
    &.headphone {
      display: none;
    }
  }

  .more-button {
    // font-family: "G-Medium";
    font-family: "G-Bold";
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.01px;
    color: #13235c;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    img {
      margin-left: 5px;
      position: relative;
      top: 2px;
      width: 7.3px;
      height: 12.8px;
      &.white {
        display: none;
      }
      &.headphone {
        display: none;
      }
      &.reach {
        display: none;
      }
      &.reach-blue {
        display: none;
      }
    }
  }

  .read-button {
    @extend .more-button;
    color: #0f2660;
    border: 1px solid #0f2660;
    border-radius: 22px;
    font-size: 16px;
    min-width: 275px;
    padding: 14px;
    margin: auto;
    margin-top: 10px;
    text-decoration: none;
    img {
      display: none;
    }
  }

  .read-button-white {
    @extend .read-button;
    color: $font-color-v2-1;
    border: 1px solid $font-color-v2-1;
    img {
      display: none;
    }
  }

  .audio-button {
    @extend .read-button;
    color: $font-color-v2-1;
    border: none;
    padding: 0;
    background-color: transparent;
    .headphone {
      width: auto;
      height: auto;
      position: relative;
      left: 5px;
      display: inline-block !important;
    }
  }

  .reach-button {
    @extend .read-button;
    color: #0d275a;
    border: none;
    padding: 0;
    background-color: transparent;

    .reach-blue {
      width: auto;
      height: auto;
      position: relative;
      left: 5px;
      display: inline-block !important;
    }
  }
  .reach-button-white {
    @extend .read-button;
    color: $font-color-v2-1;
    border: none;
    padding: 0;
    background-color: transparent;

    .reach {
      width: auto;
      height: auto;
      position: relative;
      left: 5px;
      display: inline-block !important;
    }
  }

  .more-button-white {
    font-family: "G-Medium";
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.01px;
    color: $font-color-v2-1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    img {
      margin-left: 8px;
      position: relative;
      top: 3px;
      width: 7.3px;
      height: 12.8px;
      &.pink {
        display: none;
      }
      &.headphone {
        display: none;
      }
      &.reach {
        display: none;
      }
      &.reach-blue {
        display: none;
      }
    }
  }

  .custom-button {
    width: 275px;
    font-family: "G-Book";
    font-size: 16px;
    line-height: 2.56;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fbe356;
    border-radius: 30px;
    border: solid 1px #fbe356;
    background-color: transparent;
    height: 50px;
    margin: auto;
    display: block;
    text-decoration: none;
    line-height: 47px;
    cursor: pointer;
    img {
      display: none;
    }
  }
}
.social-btn{
  width: 275px;
  font-family: "G-Book";
  font-size: 16px;
  line-height: 2.56;
  letter-spacing: 0.01px;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  border: solid 1px #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #FFFFFF;
  height: 50px;
  margin: auto;
  display: block;
  text-decoration: none;
  line-height: 47px;
  cursor: pointer;
}

.submit-btn{
  width: 275px;
  font-family: "G-Book";
  font-size: 16px;
  line-height: 2.56;
  letter-spacing: 0.01px;
  text-align: center;
  color: #0f2660;
  border-radius: 30px;
  background-color: #fbe356;
  font-weight: 800;
  height: 50px;
  margin: auto;
  display: block;
  text-decoration: none;
  line-height: 47px;
  cursor: pointer;
}