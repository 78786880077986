.animals-chambers-container {
  .section {
    padding: $padding;
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
    }
    h1 {
      @include tablet {
        font-size: 34px;
      }
      @include desktop-sm {
        font-size: 48px;
      }
    }
  }

  .section-one {
    width: 100%;
    height: auto;
    padding: 0 15px;
    h1 {
      margin-bottom: 0;
      @include laptop {
        font-size: 38px;
      }
    }
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
      h1 {
        margin-bottom: 5px;
      }
    }
  }

  .section-one {
    p {
      @include tablet {
        font-size: 18px;
        width: 500px;
      }
      @include desktop-sm {
        font-size: 22px;
      }
    }
  }

  .section-two {
    margin-top: 30px;
    @include tablet {
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .section-three {
    padding: 0;
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
      margin-top: 20px;
    }
    img {
      @include object-fit(contain);
    }
    .text-section {
      padding-bottom: 5px;
      @include tablet-lg {
        margin-bottom: 10px;
      }
      h1 {
        margin-bottom: 0;
      }
    }
  }

  .section-four {
    margin-top: 20px;
    .container {
      display: flex;
      color: $font-color-1;
      // justify-content: space-around;
      justify-content: space-between;
      align-items: center;
      .left-section {
        img {
          width: 125px;
          height: 115px;
          @include object-fit(scale-down);
          @include tablet {
            width: 165px;
            height: 155px;
            position: relative;
            left: 17px;
          }
          @include tablet-lg {
            width: 230px;
            height: 225px;
          }
          @include laptop {
            width: 260px;
            height: 255px;
          }
        }
      }
      .right-section {
        flex: 1;
        p {
          width: 80%;
          margin-left: auto;
          font-size: 18px;
          line-height: 23px;
          @include tablet {
            width: 80%;
            font-size: 24px;
            line-height: 30px;
          }
          @include tablet-lg {
            line-height: 35px;
            font-size: 30px;
          }
          @include desktop-sm {
            line-height: 45px;
            font-size: 36px;
          }
        }
      }
    }
  }

  .section-five {
    @include tablet {
      @include justify-align(space-between, center);
    }

    .description-14 {
      @include tablet {
        padding-right: 100px;
      }
      @include tablet-lg {
        font-size: 16px;
      }
      @include laptop {
        font-size: 20px;
      }
    }

    a {
      margin-top: 24px;

      @include tablet {
        margin-top: 0;
      }
      @include tablet-lg {
        width: 351px;
      }
    }
  }
}
