.home-container {
    .section {
        padding: $padding;
    }

    .section-two,
    .section-three {
        margin-top: 20px;

        @include laptop {
            margin-top: 80px;
        }

        .text-section {
            @include tablet-lg {
                margin-left: 30px;
                margin-right: 30px;
            }
            @include laptop {
                margin-left: 60px;
                margin-right: 60px;
            }
            h1 {
                @include tablet {
                    font-size: 34px;
                    margin-bottom: 0;
                }
                @include desktop-sm {
                    font-size: 48px;
                }
            }
            p {
                max-width: 512px;
                @include tablet-lg {
                    font-size: 18px;
                    margin-bottom: 12px;
                }
                @include desktop-sm {
                    font-size: 22px;
                }
            }
        }
    }

    .section-five {
        margin-top: 50px;
        @include tablet-lg {
            margin-top: 100px;
        }
    }

    .desktop {
        @include flex();
        flex-wrap: wrap;

        @include tablet-lg {
            margin: 50px 30px 20px 30px;
        }
        @include laptop {
            margin: 80px 60px 20px 60px;
        }
    }

    .section-six {
        padding: 26px 16px;
        width: 100%;
        @include tablet {
            @include flex-justify-direction(space-between, column);
            flex: 1;
        }

        @include media-width-min(991) {
            .button-container {
                position: relative;
                top: -70px;
            }
        }
        .sub-section {
            @include flex();

            @include tablet {
                // flex-direction: column;
                align-items: flex-start;
                height: 100%;
            }
            .description {
                padding-right: 15px;
                @include flex-value(1);
                @include tablet {
                    margin-bottom: 20px;
                }
            }
            .custom-button {
                width: 91px;
                height: 40px;
                font-size: 12px !important;
                @include justify-align(center, center);
                @include tablet-lg {
                    margin-top: 20px;
                    font-size: 16px !important;
                    width: 162px;
                    height: 50px;
                }
            }
        }

        h1 {
            @include tablet-lg {
                font-size: 30px;
                width: 322px;
            }
            @include desktop-sm {
                font-size: 38px;
            }
        }
        .description {
            @include tablet {
                font-size: 16px;
                margin-top: -5px;
            }
            @include desktop-sm {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    .section-nine {
        @extend .section-six;
    }

    .section-seven {
        background-color: white;
        margin-top: 80px;
        @include tablet-lg {
            margin-left: 30px;
            margin-right: 30px;
        }
        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
        @include tablet {
            // flex: 0.7;
            margin-top: 100px;
            flex: 1;
            order: 3;
        }
        @include tablet-lg {
            border-radius: 18px;
        }
        @include media-width-max(1138) {
            flex: 100%;
        }
        #tripad {
            width: 100%;
        }
        #CDSRATINGWIDGET50 {
            width: 100%;
            text-align: left;
            border: none;
            box-shadow: none;
            border-top: 1px solid #d0d0d0;
            margin-top: 3px;
            padding-top: 16px;

            #CDSROWLOC50 {
                a {
                    margin-top: 9px;
                    color: #8b8b8b;
                    font-size: 16px;
                    text-decoration: none;
                }
            }

            .bubble_45 {
                font-size: 22px;
                margin-bottom: 5px;
            }

            #CDSROWLOGO50 {
                margin-top: 9px;
                color: #8b8b8b;
            }
        }
        .details {
            border-top: 1px solid #d0d0d0;
            margin-top: 8px;
            padding-top: 15px;
            .main-text-black {
                font-size: 18px;
            }

            .rating-section {
                @include flex();
                margin-top: 8px;
                margin-bottom: 20px;
                .rating-bar {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    margin-right: 5px;
                    border: solid 1px #00a680;
                    background-color: #ffffff;
                    padding: 3px;
                    overflow: hidden;
                    .rating-color {
                        width: 100%;
                        height: 100%;
                        background-color: #00a680;
                        border-radius: 50%;
                    }
                }
            }

            .footer {
                color: #8b8b8b;
                font-size: 14px;
            }
        }
    }

    .section-eight {
        @include tablet-lg {
            margin-bottom: 80px;
        }
        .slick-list {
            padding-top: 8px;
        }
        .text-section {
            @include tablet-lg {
                padding-left: 30px;
            }
            @include laptop {
                padding-left: 60px;
            }
            h1 {
                @include tablet {
                    font-size: 34px;
                }
                @include desktop-sm {
                    font-size: 48px;
                }
            }
        }
    }
    .section-nine {
        @include laptop {
            border-right: 4px solid lightblue;
            border-left: 4px solid lightblue;
            margin-right: 25px;
            padding-left: 30px;
        }
        button {
            font-size: 16px !important;
            line-height: 0;
        }
    }
    .line {
        margin: 24px 0;
        background-color: $font-color-1;
        width: 100%;
        height: 1px;
    }

    .walkthrough-container{
        z-index: 8;
    }
}
