.sticky-footer-icons {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  background-color: white;
  width: 100%;
  position: fixed;
  // padding: 4px;
  padding-bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0px;
  gap: 5px;
}

.footer-icon {
  height: 40px;
  object-fit: contain;
  margin: 5px 0;
  border-radius: 18px;
  width: 60px;
  padding: 8px 9px;
  
}
.Test{
  fill:#000
}
.mb-footer-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  color: #000;
  padding: 3px;
  cursor: pointer;
}

.stickyfooter-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
}
.mb-footer-logo.active .footer-icon{
  background-color: #3d94f7;
  color: #fff;
}
.mb-footer-logo.active .footerIconText{
  color: #3d94f7;
  font-weight: 800;
}
// .footer-icon.active {
//   background-color: #3d94f7;
//   color: #fff;
// }

.footer-icon.active rect {
  fill: #fff;
}

.footerIconText {
  font-family: 'G-Book' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 8px;
  text-align: center;
  color: #10265f;
}

// .footerIconText.active {
//   color: #3d94f7 !important;
// }

.scanfishText {
  margin-top: 50px;
  font-weight: 500;
  font-style: normal;
  font-size: 11px;
  line-height: 9px;
  font-family: 'G-Book' !important;
  color: #10265f;
}

.cls-1{fill:#fff}
// .as-flex{
//   display: flex;
//   gap:5px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

@media only screen and (max-width: 355px) {
  .scanfishText {
    bottom: 10px;
  }
}