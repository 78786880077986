.smallskew-carousel-container {
  // padding-left: 16px;
  padding-top: 10px;
  .slick-slide {
    // Backup
    // width: 132px !important;
    // height: 130px;
    // padding-right: 25px;
    // position: relative;
    // margin-right: 30px;

    width: 158px !important;
    height: 130px;
    padding-right: 25px;
    position: relative;
    margin-right: 5px;
    @include tablet-lg {
      width: 299px !important;
      margin-right: 20px;
    }
  }

  .frame {
    position: absolute;
    top: -6px;
    left: 0px;
    @include tablet-lg {
      width: 299px;
    }
  }

  .slick-list {
    padding-top: 15px;
    padding-left: 15px;
    @include tablet-lg {
      height: 283px;
    }
    @include tablet-lg {
      padding-left: 30px;
    }
    @include laptop {
      padding-left: 60px;
    }
  }
  .slick-track {
    margin: 0;
    @include flex();
  }

  .image {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    position: relative;
    left: 3px;
    top: 7px;
    @include object-fit(cover);
    z-index: 5;
    margin: auto;
    @include tablet-lg {
      width: 91px;
      height: 91px;
      top: 20px;
      left: 10px;
    }
  }
  p {
    width: 117px;
    position: absolute;
    left: 46%;
    transform: translateX(-50%);
    font-family: "G-Medium";

    // font-size: 11px;
    font-size: 10px;
    line-height: 1.36;
    letter-spacing: 0px;
    color: #212121;
    margin-top: 15px;
    text-align: center;
    @include tablet-lg {
      left: 54% !important;
    }
  }
  .header {
    top: 48px;
    @include tablet-lg {
      font-size: 18px;
      top: 130px;
      width: 100%;
    }
  }
  .desc {
    // top: 70px;
    top: 63px;
    font-family: "G-Book";
    font-size: 9px;
    @include tablet-lg {
      font-size: 16px !important;
      top: 171px;
      width: 100%;
    }
  }
  .carousel-parent {
    position: relative;
    left: 0;
    p {
      left: 51%;
    }
  }

  @include slick-dots();
}

// .frame-carousel-container {
//     //   .slick-list {
//     //     padding: 0 20% 0 0 !important;
//     //   }

//     .slick-slide {
//       padding-right: 25px;
//       position: relative !important;
//     }

//     .frame {
//       position: absolute;
//       top: -6px;
//       left: -20px;
//     }

//     .slick-list {
//       padding-top: 10px;
//     }

//     .image {
//       width: 173px !important;
//       height: 118px !important;
//       border-radius: 54%;
//       border-top-right-radius: 97px;
//       border-bottom-right-radius: 104px;
//     }
//   }
