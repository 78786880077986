.fishscan-button {
  @include position-transform(fixed, bottom, 0, left, 50%, translate(-50%,-50%));
  @include justify-align-direction(center, center, row-reverse);
  font-family: "G-Medium";
  font-size: 16px;
  width: 90%;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: $fish-bgc;
  border: none;
  color: $fish-text;
  font-weight: 600;
  // z-index: 100;
  z-index: 8;
  // @include laptop {
  //   display: none !important;
  // }
  img {
    display: none;
  }
 
  .scan {
    display: block;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .fishscan-button {
    // bottom: 15px;
    bottom: 10px;
  }
}

