.listen-section {
    margin-top: 25px;
    .rhap_volume-controls,
    .rhap_additional-controls,
    .rhap_forward-button,
    .rhap_rewind-button,
    .rhap_progress-indicator {
        display: none !important;
    }

    .rhap_main {
        flex-direction: row-reverse !important;
    }

    .rhap_progress-filled {
        background-color: $indicator-color-1 !important;
    }

    .rhap_controls-section {
        flex: none !important;
    }

    .rhap_main-controls {
        position: relative;
        right: 6px;
        top: -2px;
        svg {
            color: white;
            width: 30px;
        }
        button {
            margin-top: 0 !important;
        }
    }

    .rhap_progress-bar {
        border-radius: 50px !important;
        overflow: hidden !important;
        height: 8px !important;
    }
    .rhap_download-progress {
        background-color: transparent !important;
    }

    .rhap_container {
        max-width: 400px !important;
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
        padding: 10px 0 !important;
        padding-bottom: 0 !important;
    }

    .rhap_time {
        color: white !important;
        font-size: 12px !important;
    }

    .main-text {
        margin-bottom: -5px;
    }
}
