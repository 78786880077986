.journey-container {
    background-image: url("image/back.svg");
    background-size: cover;
    background-position: center;
}
@media (min-width: 576px) {
    /* .journey-container {
        background-image: url("image/journey_desktop.jpg");
    } */
}

@media (min-width: 1400px) {
    .journey-container {
        background-position-y: -1102px;
    }
}
