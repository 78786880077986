.skew-shape-items {
    @supports (display: grid) {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(300px, 370px));
        // justify-content: space-around;
        grid-template: repeat(4, auto) / repeat(4, 1fr);

        @include media-width-max(1520) {
            grid-template: repeat(3, auto) / repeat(3, 1fr);
        }
        @include media-width-max(1125) {
            grid-template: repeat(2, auto) / repeat(2, 1fr);
        }
        @include media-width-max(758) {
            grid-template: repeat(1, auto) / repeat(1, 1fr);
        }
    }
    @include flex();
    flex-wrap: wrap;
}

.skew-shape-container {
    width: 343px;
    height: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 15px;
    cursor: pointer;
    @include media-width-max(759) {
        margin-left: 0;
        margin-right: auto;
    }
    @include media-width-minmax(1125, 1200) {
        margin-left: auto;
        margin-right: auto;
    }
    position: relative;
    margin-bottom: 15px;

    .image {
        width: 117px;

        height: 93px;
        z-index: 1;
        @include position(absolute, left, 12px, top, 17px);
        @include justify-align(center, center);
        img {
            width: 100%;
            height: 100%;
            @include object-fit(scale-down);
            transform: scale(0.8);
        }
    }

    .description {
        width: 60%;
        height: 100%;
        margin-left: auto;
        @include flex-align(center);

        p {
            width: 195px;
            font-family: "G-Medium";
            font-size: 16px;
            line-height: 1.25;
            letter-spacing: 0.01px;
            text-align: left;
            color: #212121;
        }
    }
}
.no-results {
    text-align: center;
    margin-bottom: 25px;
    p {
        text-align: center;
    }
}

.big-skew-shape-container {
    @extend .skew-shape-container;
    background-size: contain;
    cursor: pointer;
    .image {
        width: 93px;
        height: 106px;
        border-radius: 8px;
        left: 9px;
        z-index: -1;
        top: 9px;
        border-bottom-left-radius: 3px;
        overflow: hidden;
        img {
            @include object-fit(cover);
            transform: none;
        }
    }
    .description {
        @include flex-align-direction(center, column);
        padding-top: 18px;
        .desc {
            line-height: 1.67;
            color: #737373;
            font-size: 12px;
            font-family: "G-Book";
        }
    }
}

.big-skew-shape-container {
    &.activity-list-skew {
        .description {
            width: 65%;
        }
        p {
            width: 200px;
        }
    }
}

.expertskew {
    .description {
        padding-top: 41px;
    }
    .image {
        img {
            width: 87% !important;
            height: 80%;
        }
    }
}
