.long-carousel-popup-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.89);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: flex-end;

    .wrapper {
        background-color: #fff;
        border-radius: 30px 30px 0 0;
        min-height: 565px;
        position: relative;
        @include tablet {
            min-height: 500px;
            max-width: 550px;
            margin: auto;
            border-radius: 30px;
        }
        img {
            @include position(absolute, right, 18px, top, 24px);
        }

        .heading-section {
            padding: 0 16px;
            .main-text {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }

    .list-group {
        padding: 0 16px;
        .list-item {
            display: flex;
            align-items: center;
            margin: 24px 0;
            .icon {
                width: 27px;
                height: 27px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                margin-right: 19px;
                font-family: arial , sans-serif;
            }
            .name {
                font-family: "G-Book";
                font-weight: 600;
                @include tablet-lg {
                    font-size: 16px !important;
                }
            }
        }
    }
    .button-container {
        padding: 15px 0;
        border-top: 1px solid silver;
        .normal-button {
            width: 167px;
            margin: auto;
        }
    }
}
