$min: 645;
$max: 1199;
footer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 450px;
  margin-top: 42px;
  text-align: center;
  color: #dddddd;
  @include tablet {
    margin-top: 73px;
  }

  @include tablet-lg {
    @include flex-justify(space-between);
    flex-wrap: wrap;
    padding: 0 60px;
    min-height: auto;
  }
  @include laptop {
    padding-top: 40px;
    padding: 0 15px;
  }
  @include desktop {
    padding-top: 40px;
    padding: 0 60px;
  }

  @include media-width-minmax($min, $max) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .section-one {
    @include tablet-lg {
      flex: 1;
    }
    @include media-width-minmax($min, $max) {
      grid-column: 1/4;
    }
    @include laptop {
      width: 283px;
      flex: none;
    }
    .logo {
      width: 147px;
      @include tablet-lg {
        width: 255px;
      }
    }
    .timing {
      margin-top: 14px;
      margin-bottom: 40px;
      font-family: "G-Medium";
      font-size: 12px;
      line-height: 3.42;
      letter-spacing: 0px;
      @include tablet-lg {
        font-size: 14px;
      }

      img {
        margin-right: 5px;
      }
    }
  }

  .section-two {
    @include flex();
    flex-wrap: wrap;
    padding-left: 27px;
    @include media-width-max(333) {
      padding-left: 20px;
    }

    @include tablet-lg {
      flex: 1;
      display: grid;
      grid-template-columns: 231px 230px;
      grid-gap: 25px;
      grid-template-rows: repeat(3, 39px);
    }
    @include media-width-minmax($min, $max) {
      grid-column: 1/2;
    }
    @include laptop {
      width: 512px;
      grid-template-columns: auto auto;
      justify-content: space-around;
    }
    @include media-width-minmax(400, 645) {
      display: grid;
      grid-template-columns: 150px auto;
      justify-content: space-evenly;
    }
    a {
      font-family: "G-Book";
      font-size: 14px;
      @include flex-value(50%);
      text-align: left;
      color: #dddddd;
      text-decoration: none;
      line-height: 30px;
      letter-spacing: 0.01px;
      margin-bottom: 27px;
      @include media-width-max(333) {
        font-size: 12px;
      }
      @include tablet-lg {
        font-size: 18px;
      }
    }
  }

  .section-three {
    @include tablet-lg {
      flex: 1;
    }
    @include media-width-minmax($min, $max) {
      grid-column: 3/4;
    }
    @include laptop {
      width: 283px;
      flex: none;
    }
    h1 {
      opacity: 0.33;
      font-family: "G-Medium";
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.05;
      letter-spacing: 0.01px;
      text-align: center;
      color: $font-color-1;
    }
    .social-icons {
      img {
        margin: 0 8px;
      }
    }
  }

  .section-four {
    margin-top: 50px;
    padding-bottom: 13px;

    @include tablet-lg {
      flex: 100%;
      @include flex-justify(space-between);
    }
    @include media-width-minmax($min, $max) {
      grid-column: 1/4;
    }
    p{
      font-family: "G-Medium";
      font-size: 10px;
      line-height: 2;
      letter-spacing: -0.49px;
      text-align: center;
      color: #ffffff;
      
      @include tablet-lg {
        font-size: 14px;
        letter-spacing: -0.88px;
      }
      @include laptop {
        font-size: 18px;
      }
      &:nth-child(2){
        @media (max-width: 768px){
            padding-bottom: 8rem;
          }
        }
        }
    }
  }
