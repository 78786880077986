/* Font  */
$font-color-1: #ffffff;
$font-color-2: #737373;
$font-color-3: #c2c2c2;
$font-color-4: #2f2f2f;
$font-color-primary: #0f2652;
$font-color-v2-1: #f9e256;

// Line Color
$line-color: #2ed7c4;
$line-color-2: #00a4e0;

//Indicator color
$indicator-color-1: #f9e256;

// Fish Button
$fish-bgc: #fbe356;
$fish-text: #0f2652;

/* Header */
$header-color: #184f90;
$header-color-2: #0f2660;

/* Section Padding */
$padding: 15px 16px;

/* Box Shadow */
$box-shadow-1: 0 3px 6px #efefef;

/* Scroll */
$scroll-background: transparent;

/* overlapped image top*/

$overlapped-image: -80px;
