.fishScanBtn {
    @include position-transform(fixed, bottom, 0, left, 50%, translate(-50%,-50%));
    @include justify-align-direction(center, center, row-reverse);
    font-family: "G-Medium";
    font-size: 16px;
  
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: none;
    border-radius: none !important;
    // background: $fish-bgc;
    border: none;
    // color: $fish-text;
    // font-weight: 600;
    // z-index: 100;
    z-index: 8;
    // @include laptop {
    //   display: none !important;
    // }
    img {
      display: none;
    }
   
    .scan {
      display: block;
    //   margin-right: 10px;
      // width: 70px;
      height: 65px;
      border: none !important;
      border-radius: none !important;
      box-shadow: none;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .fishScanBtn {
      // bottom: 15px;
      bottom: 4px;
      z-index: 10000;
    }
  }
  
  