.activity-detials-container {
    @include right-cross-header(0) {
        .header-section {
            height: 59px;
            background-color: $header-color-2;
            @include tablet-lg {
                display: none;
            }
        }
    }

    @include top-image-section();

    .image-section {
        @include tablet-lg {
            width: 50%;
            height: auto;
        }
    }
    h1 {
        margin-bottom: 5px;
    }

    .section {
        padding: $padding;
    }

    .desktop-section-1 {
        @include tablet-lg {
            display: flex;
            margin-left: 30px;
            margin-right: 30px;
        }

        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    .section-one {
        @include tablet-lg {
            width: 50%;
            padding-left: 30px;
            padding-right: 30px;
        }
        .description-18 {
            display: none;
            @include tablet {
                font-size: 20px;
            }
            @include tablet-lg {
                font-size: 24px;
                font-family: "G-Bold";
                display: block;
                margin-bottom: 20px;
            }
            @include laptop {
                font-size: 28px;
            }
        }
        .list-group {
            margin-top: 36px;
            .list-item {
                @include justify-align(flex-start, center);
                margin: 24px 0;
                @include tablet-lg {
                    margin: 11px 0;
                }
                .items {
                    width: 40px;
                    margin-right: 20px;
                    img {
                        width: 25px;
                        height: 25px;
                        @include object-fit(contain);
                    }
                }
                h1 {
                    font-family: "G-Book";
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 1.2;
                    letter-spacing: 0.01px;
                    text-align: left;
                    color: $font-color-1;
                }
            }
        }

        .button-container {
            position: fixed;
            bottom: -1px;
            left: 0;
            right: 0;
            opacity: 1;
            z-index: 9;
            background-image: linear-gradient(to bottom, rgba(15, 38, 96, 0), #0f2660 84%);
            padding-bottom: 16px;
            @include tablet-lg {
                position: static;
                width: 321px;
                margin: auto;
            }
        }
    }

    .section-two {
        margin-bottom: 15px;
        @include responsive-h1-tag();
        @include tablet-lg {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 45px;
            margin-bottom: 45px;
        }

        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
        .skew-container {
            height: auto;
            margin: auto;
            margin-top: 16px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 18px 0;
            @include flex-justify-direction(center, column);

            @include tablet-lg {
                margin: 0;
                margin-top: 20px;
            }

            @include laptop {
                padding-top: 56px;
                padding-bottom: 56px;
            }
            .container {
                margin: 15px 17px;
                @include justify-align(space-around, center);
                &:nth-child(even) {
                    flex-direction: row-reverse;
                }
                @include media-width-max(500) {
                    margin: 15px 40px;
                }
                @include media-width-max(375) {
                    margin: 15px 30px;
                }
                @include tablet {
                    margin: 15px 130px;
                    height: 77px;
                }
                @include tablet-lg {
                    height: 130px;
                }
                .image {
                    flex: 0.5;
                    text-align: center;
                }
                img {
                    width: 60px;
                    @include object-fit(scale-down);

                    @include tablet {
                        width: 75px;
                        @include object-fit(cover);
                    }
                    @include tablet-lg {
                        width: 140px;
                        @include object-fit(cover);
                    }
                    @include laptop {
                        width: 175px;
                        @include object-fit(cover);
                    }
                    @include desktop {
                        width: 185px;
                        @include object-fit(cover);
                    }
                }
                .summary {
                    position: relative;
                    flex: 1;
                    &::before {
                        @include line();
                        left: -12px;
                        @include tablet {
                            left: -25px;
                        }
                    }
                    @include media-width-max(500) {
                        flex: 0.5;
                    }
                    @include media-width-max(375) {
                        flex: 0.7;
                    }
                    @include tablet {
                        flex: 0.6;
                    }
                    @include laptop {
                        flex: 1;
                    }

                    p {
                        font-size: 12px;
                        line-height: 1.4;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #737373;
                        padding: 3px 0;
                        @include tablet-lg {
                            font-size: 16px;
                            padding: 23px 0;
                            display: flex;
                            align-items: center;
                        }
                        @include laptop {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .section-three {
        @include responsive-h1-tag();
        h1 {
            @include tablet-lg {
                margin-left: 30px;
                margin-right: 30px;
            }

            @include laptop {
                margin-left: 60px;
                margin-right: 60px;
            }
        }
    }

    .section-four {
        margin-top: 32px;
        @include responsive-h1-tag();
        @include tablet-lg {
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
        h1 {
            margin-bottom: 24px;
        }
    }

    .section-five {
        @include responsive-h1-tag();
        h1 {
            @include tablet-lg {
                margin-left: 30px;
                margin-right: 30px;
            }

            @include laptop {
                margin-left: 60px;
                margin-right: 60px;
            }
        }
    }
}
