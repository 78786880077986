.animal-chamber-list-container {
  .header-section {
    display: none;
  }
  @include media-width-max(968) {
    @include left-arrow-header();
  }
  padding-top: 18px;
  @include tablet {
    margin-top: 30px;
  }
  @include tablet-lg {
    margin-left: 30px;
    margin-right: 30px;
  }
  @include laptop {
    margin-left: 60px;
    margin-right: 60px;
  }

  .location-icon {
    position: absolute;
    right: 17px;
    top: 17px;
    z-index: 1;
    @include tablet {
      right: 100px;
      top: 83px;
    }
    @include media-width-minmax(770, 969) {
      right: 50px;
      top: 43px;
    }
  }

  .skew-shape-items {
    @include media-width-min(759) {
      padding-left: 15px;
    }
  }

  .mini-map {
    width: 100%;
    margin-bottom: 15px;
  }

  .skew-shape-container {
    @include media-width-max(759) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .input-group {
    text-align: center;
    position: relative;
    padding: 0 10px;
    margin-bottom: 30px;
    img {
      @include position-transform(absolute, left, 28px, top, 50%, translateY(-50%));
    }
    input {
      font-family: "G-Book";
      width: 100%;
      height: 50px;
      border-radius: 30px;
      padding-left: 45px;
      border: none;
      box-shadow: 0 5px 15px 0 rgba(48, 48, 48, 0.2);
      box-sizing: border-box;
      &::placeholder {
        opacity: 0.26;
        color: #2f2f2f;
      }
    }
  }

  .audio-section {
    @include tablet-lg {
      margin-top: 15px;
      margin-bottom: 80px;
    }
  }

  .bottom-container {
    @include media-width-max(767) {
      padding: $padding;
    }
    @include tablet {
      margin-top: 48px;
      margin-left: 30px;
      margin-right: 30px;
    }
    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .description-14 {
    margin-top: 32px;
    @include tablet-lg {
      font-size: 18px;
    }
    @include laptop {
      font-size: 22px;
    }
  }
}
