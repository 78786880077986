.reverse-skew-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 33px;
    padding-bottom: 33px;
    @include flex-justify-direction(center, column);

    @include tablet-lg {
        margin: 0;
        margin-top: 35px;
    }

    @include laptop {
        padding-top: 56px;
        padding-bottom: 56px;
    }
    .container {
        margin: 15px 70px;
        @include justify-align(space-around, center);
        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        @include media-width-max(500) {
            margin: 15px 40px;
        }
        @include media-width-max(375) {
            margin: 15px 30px;
        }
        @include tablet {
            margin: 15px 90px;
            height: 77px;
        }
        @include tablet-lg {
            height: 130px;
        }

        .image {
            text-align: center;

            img {
                width: 60px;
                @include object-fit(scale-down);

                margin-right: 50px;
                @include tablet {
                    width: 75px;
                    @include object-fit(cover);
                }
                @include tablet-lg {
                    width: 100px;
                    @include object-fit(cover);
                }
                @include desktop {
                    width: 120px;
                    @include object-fit(cover);
                }
            }
        }

        .summary {
            position: relative;
            flex: 1;
            &::before {
                @include line();
                left: -12px;
                @include tablet {
                    left: -25px;
                }
            }
            &:nth-child(2) {
                p {
                    width: 100%;
                }
            }

            h1 {
                font-size: 16px;
                line-height: 1.25;
                letter-spacing: 0.01px;
                text-align: left;
                color: #212121;
                @include tablet-lg {
                    font-size: 26px;
                }
            }
            p {
                font-size: 12px;
                line-height: 1.4;
                letter-spacing: 0px;
                text-align: left;
                color: #737373;
                margin-top: 8px;
                @include tablet-lg {
                    font-size: 18px;
                }
            }
        }
    }
}
