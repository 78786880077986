.journey-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $header-color;
  padding: 2.3rem 10px 2.5rem 10px;
  * {
    text-align: center;
  }
  .main-text {
    font-size: 18px;
  }

  #greek {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-20%);
    z-index: 9;
    @include media-width-min(400) {
      width: 24vw;
    }
    @include media-height-max(742) {
      width: 75px;
    }
    @media (max-width:340px)
    {
      padding-top: 2rem;
      width: 22vw;
    }
  }
 

  .highlight {
    color: $font-color-v2-1;
  }

  .top-section {
    max-width: 375px;
    width: 100%;
    margin: auto;
    .top-text{
      font-size: 11px;
      color: #fff !important;
      padding: .5rem;
      background-color: #00000040;
      width: fit-content;
      margin: auto;
    }
    .fishscan-button {
      max-width: 343px;
      width: 100%;
      position: unset;
      transform: unset;
      margin: auto;
      margin-top: 26px;
      cursor: pointer;

    }

    .icons-section {
      display: flex;
      justify-content: space-between;
      color: #ffffffa6;
      font-family: "G-Book";
      font-size: 12px;
      margin-top: 30px;
      section {
        width: 170px;
      }
      .icon {
        width: 64px;
        border-radius: 22px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5.3px);
        background-color: rgba(255, 255, 255, 0.18);
        margin: auto;
        margin-bottom: 10px;
        img {
          width: 35px;
        }
      }
    }
  }

  .middle-section {
    //flex: 1;
    opacity: 0.28;
    font-family: "G-Bold";
    font-size: 18px;
    letter-spacing: 0.01px;
    text-align: center;
    color: #ffffff;
    @include justify-align(center, flex-end);
    padding-bottom: 2rem;
    span {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 40vw;
        height: 1px;
        opacity: 1;
        background-color: #ffffff;
        right: 36px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        content: "";
        position: absolute;
        width: 40vw;
        height: 1px;
        opacity: 1;
        background-color: #ffffff;
        left: 36px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (min-width: 300px) and (max-width: 667px) {
      padding-top: 2rem;
    }
  }

  .journey-bottom {
    // .btn-logo {
    //   max-width: 343px;
    //   width: 100%;
    //   margin-top: 30px;
    //   cursor: pointer;
    // }
    flex: 1;
    .journey-logo{
      // max-width: 343px;
      width: 90%;
      margin: 1rem auto;
      display: flex;
      justify-content: center;
      background-color: #fbe356;
      cursor: pointer;
      border-radius: 50px;
    }
    .btn-logo {
      width: 100px;
      padding: 0.5rem;
      height: auto;
    }
  }
}
