.icons-skew-container {
    min-height: 182px;
    background-size: 113% 100%;
    background-position-x: -20px;
    background-repeat: no-repeat;
    padding: 22px 16px;
    box-sizing: border-box;
    text-align: center;
    @include tablet {
        background-position-x: -36px;
    }
    @include tablet-lg {
        @supports (justify-content: space-evenly) {
            @include justify-align(space-evenly, center);
        }
        @include justify-align(space-around, center);
        flex-direction: row-reverse;
    }
    @include laptop {
        background-position-x: -80px;
    }
    .timeline {
        @include media-width-max(450) {
            width: 100%;
        }
    }
    .description-black-14 {
        color: $font-color-2;
        margin-bottom: 10px;

        @include tablet {
            font-size: 20px;
        }
        @include tablet-lg {
            width: 56%;
            font-size: 24px;
        }
        @include laptop {
            font-size: 28px;
        }
    }
    .bottom-p {
        margin-top: 10px;
        margin-bottom: 0;
    }
}
