.description {
  font-family: "G-Book";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: left;
  color: $font-color-1;
}
.description-black {
  @extend .description;
  color: $font-color-4;
}

.description-14 {
  @extend .description;
  font-size: 14px;
  color: $font-color-1;
}

.description-black-14 {
  @extend .description-14;

  color: $font-color-4;
}

.description-12 {
  @extend .description;
  font-size: 12px;
  color: $font-color-1;
}

.description-black-12 {
  @extend .description-12;
  color: $font-color-4;
}

.description-18 {
  @extend .description;
  font-size: 18px;
  color: $font-color-1;
  line-height: 1.4;
  letter-spacing: 0.01px;
}
