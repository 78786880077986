* {
  outline: none !important;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "G-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f2660;
}

// Animation to Shrink the contentSection
.root-container {
  min-height: 100vh;
  &.shrinkAnimation {
    width: 100%;
    height: 584px;
    @include position-transform(fixed, right, -58%, top, 50%, translateY(-50%) scale(0.5));
    border: 6px solid white;
    border-radius: 67px;
    padding: 44px 10px;
    overflow: hidden;
    @include transition(1s ease-in-out);
    z-index: 99999999;
    @include media-width-min(538) {
      height: 755px;
      right: -48%;
      top: -1%;
    }
  }

  .overlay-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    z-index: 99999;
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: "G-Bold";
}

p {
  font-family: "G-Book";
}

a {
  text-decoration: none;
}

/*======= Scroll Start */
/* scrollbar width */
::-webkit-scrollbar {
  width: 5px;
}

.margin-top {
  margin-top: 24px;
}

/* scrollbar Track */
::-webkit-scrollbar-track {
  background: $scroll-background;
  border-radius: 10px;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: $scroll-background;
  border-radius: 10px;
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scroll-background;
}
/*======= Scroll End */

select {
  font-family: "G-Regular";
  border: 1px solid #efefef;
  width: 280px;
  margin: 15px;
  padding: 15px;
}

.text-section {
  padding: 16px;
}

.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-7 {
  margin-top: 7px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-10 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 1px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-6 {
  margin-bottom: 6px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-9 {
  margin-bottom: 9px;
}
.mb-10 {
  margin-bottom: 10px;
}

.mr-1 {
  margin-right: 1px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-6 {
  margin-right: 6px;
}
.mr-7 {
  margin-right: 7px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-9 {
  margin-right: 9px;
}
.mr-10 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 1px;
}
.ml-2 {
  margin-left: 2px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-6 {
  margin-left: 6px;
}
.ml-7 {
  margin-left: 7px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-9 {
  margin-left: 9px;
}
.ml-10 {
  margin-left: 10px;
}

.btn-width {
  max-width: 321px;
}

.pointer {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.text-case {
  text-transform: lowercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.placeholder {
  background-color: white;
  animation: blinker 1.8s ease-in-out infinite;
  @keyframes blinker {
    0% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.3;
    }

    to {
      opacity: 0.1;
    }
  }
}
