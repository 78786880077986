.help-container {
    .section {
        padding: $padding;
        @include tablet-lg {
            padding-left: 60px;
            padding-right: 60px;
        }

        .accordion-inner {
            @include tablet-lg {
                // display: flex;
                // flex-wrap: wrap;
                // align-items: flex-start;
                display: grid;
                grid-template-columns: 50% 50%;
                align-items: flex-start;
            }
            h1 {
                grid-column: 1/3;
                @include tablet-lg {
                    flex: 100%;
                }
            }
            .accordion-container {
                @include tablet-lg {
                    // max-width: 47%;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    .section-one {
        h1 {
            @include tablet {
                font-size: 34px;
            }

            @include desktop-sm {
                font-size: 48px;
            }
        }
        .input-group {
            text-align: center;
            position: relative;
            img {
                @include position-transform(absolute, left, 15px, top, 50%, translateY(-50%));
            }
            input {
                font-family: "G-Book";
                width: 100%;
                height: 50px;
                border-radius: 30px;
                padding-left: 45px;
                border: none;
                box-shadow: 0 5px 15px 0 rgba(48, 48, 48, 0.2);
                box-sizing: border-box;
                &::placeholder {
                    opacity: 0.26;
                    color: #2f2f2f;
                }
            }
        }
    }

    .section-two {
        h1 {
            margin-top: 32px;

            @include tablet {
                font-size: 34px;
            }
            @include desktop-sm {
                font-size: 48px;
            }

            &:nth-child(1) {
                margin-top: 0;
            }
        }
        .accordion-container {
            margin-bottom: 16px;
            background-color: white;
            border: 3px solid #9ecaef;
            border-radius: 25px;
            overflow: hidden;
            position: relative;
            transition: 0.3s;
            .arrow-icon {
                position: absolute;
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                transition: 0.3s;
                pointer-events: none;
                &.arrow-icon-close {
                    // top: 27%;
                    top: 28px;
                    transform: translateY(-50%) rotate(180deg);
                }
            }
            .panel {
                height: 0;
                overflow: hidden;

                &.isActive {
                    padding: 16px;
                    height: auto;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 0;
                        width: 96%;
                        height: 3px;
                        background-color: #2ed7c4;
                    }
                }
            }
            .accordion-button {
                width: 100%;
                background-color: #fff;
                border: none;
                font-size: 14px;
                font-family: "G-Medium";
                text-align: left;
                padding: 16px;
                box-sizing: border-box;
                line-height: 18px;
                padding-right: 48px;
            }
        }
    }

    .section-three {
        padding-top: 0;
        overflow: hidden;
        h1 {
            @include tablet {
                font-size: 34px;
            }
            @include desktop-sm {
                font-size: 48px;
            }
        }
        p {
            @include tablet {
                font-size: 18px;
                // width: 500px;
            }
            @include desktop-sm {
                font-size: 22px;
            }
        }
        .map-section {
            max-width: 100%;
            margin: auto;
            margin-top: 25px;
            height: 365px;
            position: relative;
            overflow: hidden;
            background-repeat: no-repeat;
            // border-radius: 70px;
            border-radius: 25px;
            @include tablet {
                height: 596px;
            }
            .footer-image {
                @include position(absolute, bottom, -5px, left, 2px);
                width: 100%;
            }

            span {
                @include position-transform(absolute, bottom, 18px, left, 50%, translateX(-50%));
            }
        }
    }

    .section-four {
        margin-top: 32px;
        border-top: 3px solid #9ecaef;
        border-bottom: 3px solid #9ecaef;
        height: 104px;
        background-color: white;
        padding-left: 16px;
        @include flex-align(center);
        border-radius: 115px / 8px;
        @include tablet {
            height: 180px;
        }
        p {
            text-align: center;
            margin: auto;
            text-align: left;
            @include tablet {
                font-size: 24px;
            }
        }
        span,
        a {
            color: #0f2660;
            font-family: "G-Bold";
            display: block;
        }
    }

    .normal-button {
        @include tablet {
            max-width: 325px;
        }
    }
}
