// 3 SCSS Code for Each Files.

// Accuracy.js
.accuracy-container {
    background-color: rgba(0, 0, 0, 0.84);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 5px 6px #0a0a0a8f;
    z-index: 999999;
    @include justify-align(center, center);

    .big-image {
        margin-bottom: 30px;
    }
    .section {
        width: 268px;
        background-color: #fff;
        border-radius: 14px;
        padding: 19px 16px;
        .description-17-black {
            font-family: "G-Medium";
            font-weight: 500;
            font-size: 17px;
            color: #000000;
        }

        .description-12-black {
            width: 195px;
            color: #000000;
            font-size: 12px;
            margin-top: 16px;
            margin-bottom: 4px;
        }

        .image-section {
            text-align: center;
        }
        button {
            width: 151px;
            height: 38px;
            border-radius: 22px;
            background-color: #e42535;
            font-size: 12px;
        }
    }
}

// Accuracy.js END

// ErrorMessageFish.js

.error-message-fish-container {
    padding: $padding;
    padding-top: 0;
    text-align: center;
    @include right-cross-header(0) {
        .header-section {
            height: 59px;
            background-color: $header-color-2;
        }
    }

    .section {
        .description-14 {
            text-align: center;
        }
        img {
            margin-bottom: 30px;
        }
    }
}
