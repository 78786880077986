.activity-list-container {
    // Section
    [class*="section-"] {
        margin-bottom: 32px;
        padding-left: 16px;
        padding-right: 16px;
    }
    @include responsive-h1-tag();

    .section-one {
        padding: 0;
        h1 {
            margin-bottom: 0;
        }

        .text-section {
            @include tablet-lg {
                margin-left: 30px;
                margin-right: 30px;
            }
            @include laptop {
                margin-left: 60px;
                margin-right: 60px;
            }
        }
    }

    .section-two {
        @include tablet-lg {
            margin-left: 30px;
            margin-right: 30px;
        }
        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    .all-sections {
        @include tablet-lg {
            margin-left: 30px;
            margin-right: 30px;
        }
        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    // Input Search Filter
    .input-group {
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        img {
            @include position-transform(absolute, right, 18px, top, 50%, translateY(-50%));
        }
        input {
            font-family: "G-Book";
            width: 100%;
            height: 50px;
            border-radius: 30px;
            padding-left: 23px;
            border: none;
            box-shadow: 0 5px 15px 0 rgba(48, 48, 48, 0.2);
            box-sizing: border-box;
            &::placeholder {
                opacity: 0.26;
                color: #2f2f2f;
            }
        }
    }

    h1 {
        margin-bottom: 24px;
    }

    .section-list {
        display: flex;
        flex-wrap: wrap;

        h1 {
            flex: 100%;
        }

        .big-skew-shape-container {
            margin-left: 0;
            @include media-width-max(745) {
                margin-right: 11px;
            }
            @include media-width-min(756) {
                margin-right: 15px;
            }
            @include tablet-lg {
                margin-right: 20px;
            }

            .image {
                width: 93px;
                height: 100px;
                left: 5px;
                top: 8px;
                img {
                    border-radius: 18px;
                }
            }
        }
    }

    // Big Skew Container
    .big-skew-shape-container {
        .description {
            padding-right: 27px;
        }
    }

    .noresult {
        color: white;
        text-align: center;
        font-size: 18px;
    }

    .iconachor {
        /* position: absolute; */
        right: 24px;
        margin-top: 0;
        text-align: right;
        width: 100%;
        display: block;
        padding-right: 24px;
        padding-bottom: 6px;
    }

    .square-carousel-container {
        .parent {
            @include tablet {
                border-radius: 30px;
            }
            @include tablet-lg {
                height: 500px;
            }
            &::before {
                @include before() {
                    height: 100%;
                    background-image: linear-gradient(to bottom, rgba(13, 13, 13, 0), rgba(0, 0, 0, 0.78));
                }
            }
            .description {
                @include tablet {
                    bottom: 27px;
                }
            }

            .desc {
                @include tablet {
                    font-size: 16px;
                    margin-top: 25px;
                }
                @include tablet-lg {
                    font-size: 22px;
                }
                @include desktop-sm {
                    font-size: 26px;
                }
            }
        }

        .image {
            object-fit: cover;
        }
    }
    .activity-list-skew {
        .image {
            width: 101px !important;
            height: 107px !important;
        }
    }

    .expert-desc {
        padding-top: 11px;
    }
}
