.text-button-container {
  @include justify-align(space-between, baseline);
  a {
    color: $font-color-v2-1;
    font-family: "G-Bold";
    font-weight: bold;
    font-size: 12px;
    line-height: 1.43;
    letter-spacing: 0.01px;
    text-transform: capitalize;
    text-decoration: underline;
    @include tablet {
      font-size: 18px;
    }
    img {
      position: relative;
      left: 4px;
      top: 2px;
      @include tablet {
        top: 1px;
        width: 8px;
      }
    }
  }
}
