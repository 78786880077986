.learn-about-fis-container {
    .text-section {
        padding-bottom: 0;
        h1 {
            @include tablet {
                font-size: 34px;
                margin-bottom: 24px;
            }
            @include desktop-sm {
                font-size: 48px;
            }
        }
    }
    .scan-section {
        min-height: 151px;
        border-radius: 30px;
        background-color: $font-color-1;
        padding: 15px;
        // margin: 15px;
        box-sizing: border-box;
        border: 4px solid #9ecaef;
        @include justify-align(space-between, center);
        @include tablet {
            padding: 25px 42px;
        }
        @include tablet-lg {
            padding: 35px 42px;
        }
        p {
            // width: 203px;
            width: 65%;
            color: $font-color-2;
            border-left: 4px solid $line-color;
            padding-left: 10px;
            margin-left: 8px;

            @include tablet {
                font-size: 14px;
                @include flex-align(center);
                height: 115px;
                padding-left: 30px;
            }
            @include tablet-lg {
                font-size: 18px;
                padding-left: 50px;
                height: 136px;
            }
            @include laptop {
                font-size: 22px;
            }
        }
        .image {
            width: 77px;

            margin: auto;
            @include tablet-lg {
                width: 120px;
            }
            @include laptop {
                width: 147px;
            }
        }
    }
}
