.animals-details-container {
  @include right-cross-header();
  .header-section {
    background-color: $header-color-2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 15px 0;
    @include tablet-lg {
      position: static;
      padding: 15px 60px;
      p {
        width: 100%;
        text-align: left;
        text-transform: capitalize;
        @include tablet-lg {
          font-family: "G-Bold";
          font-size: 30px;
        }
        @include laptop {
          font-family: "G-Bold";
          font-size: 36px;
        }
      }
      img {
        display: none;
      }
    }
  }

  .walkthrough-container {
    z-index: 9;
  }

  .section {
    padding: $padding;
  }

  .section-one {
    @include tablet-lg {
      font-size: 19px;
    }
    @include laptop {
      font-size: 20px;
    }
    .text-section {
      @include tablet-lg {
        padding-left: 60px;
        padding-right: 60px;
      }
    }
  }

  .section-two {
    @include responsive-h1-p-tag();

    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
      margin-bottom: 25px;
    }
    @include laptop {
      margin: 70px 0;
      @include flex-align(center);
    }
  }

  .desktop-inner {
    @include laptop {
      flex: 1;
      padding-right: 90px;
    }
  }

  .audio-section {
    @include laptop {
      margin-top: -20px;
    }
  }
  .youtube-link {
    font-family: "G-Medium";
    color: $font-color-v2-1;
    font-size: 16px;
    text-align: center;
    width: 40%;
    display: block;
    margin: auto;
    margin-top: 30px;
    img {
      position: relative;
      top: 2px;
      left: 8px;
    }
  }

  .section-three {
    background-repeat: no-repeat;
    background-size: calc(100% + 220px) 100%;
    background-position-x: -96px;
    padding: 20px 35px 25px 25px;
    width: 100%;
    min-height: auto;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    @include flex-justify-direction(center, column);

    @include tablet-lg {
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 190px;
      align-items: center;
      margin-bottom: 80px;
    }
    h1 {
      text-shadow: none;
      @include tablet-lg {
        width: 30%;
        margin-right: 35px;
        font-size: 22px;
      }
      @include laptop {
        font-size: 24px;
      }
    }
    .description-black-18 {
      font-size: 18px;
      color: #909090;
      margin-bottom: 15px;
      @include tablet-lg {
        width: 65%;
        font-size: 19px;
      }
      @include laptop {
        font-size: 20px;
      }
    }

    .more-button {
      @include tablet-lg {
        font-size: 16px;
      }
    }
  }

  .section-four {
    @include responsive-h1-p-tag();
    @include tablet-lg {
      .text-section {
        padding: 0;
      }
      padding-left: 60px;
      padding-right: 60px;
    }
    .button-container {
      // margin-top: 18px;
    }
    ul {
      padding-left: 3px;
    }
    li {
      list-style: none;
      &::before {
        content: "- ";
      }
    }
  }
}
