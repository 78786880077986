.curve-carousel-container {
    // @include media-width-max(991) {
    //     padding-left: 16px;
    // }

    .parent {
        width: 200px !important;
        border-radius: 30px;
        overflow: hidden;
        @include tablet-lg {
            width: 255px !important;
        }
    }

    .slick-slide {
        width: 200px !important;
        min-height: 172px;
        position: relative;
        margin-right: 25px;
        background: white;
        border-radius: 30px;
        @include tablet {
            margin-right: 40px;
        }
        @include tablet-lg {
            margin-right: 70px;
            width: 255px !important;
        }
        @include laptop {
            // margin-right: 130px;
            margin-right: 115px;
        }

        @include desktop-lg {
            margin-right: 120px;
        }
    }

    .slick-track {
        @include flex();
        margin-bottom: 11px;
        margin-left: 0;
        @include tablet-lg {
            justify-content: flex-start;
            padding-left: 30px;
        }
        @include laptop {
            padding-left: 70px;
        }
    }

    .slick-list {
        @include tablet-lg {
            // height: 255px;
        }
    }

    .single-item {
        position: relative;
        left: -55px;
    }

    .slick-list {
        padding-top: 15px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .image {
        width: 100%;
        height: 129px;
        border-radius: 30px;
        object-fit: cover;
        @include object-fit(cover);
        @include tablet-lg {
            top: 3px;
            height: 144px;
        }
        @include laptop {
            top: 6px;
            height: 176px;
        }
    }
    .desc-1 {
        text-align: center;
        font-family: "G-Medium";
        font-size: 10px;
        line-height: 1.36;
        letter-spacing: 0px;
        color: #0f2660;
        margin-top: 11px;
        width: 100%;
        padding-bottom: 10px;
        span {
            font-weight: 700;
            font-size: 12px;
        }

        @include tablet-lg {
            font-size: 13px !important;
            span {
                font-size: 14px;
            }
        }
    }

    .desc-padding {
        padding-left: 10px;
        padding-right: 10px;
        font-family: "G-Book";
    }

    @include slick-dots($indicator-color-1);
}
