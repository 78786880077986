.fish-error-container {
  background-color: rgba(0, 0, 0, 0.84);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  @include justify-align(center, center);

  .big-image {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 25%;
    z-index: -1;
  }
  .section {
    width: 237px;
    background-color: #fff;
    border-radius: 14px;
    padding: 19px 16px;
    .description-17-black {
      width: 178px;

      font-family: "G-Medium";
      font-weight: 500;
      font-size: 17px;
      color: #000000;
    }

    .description-12-black {
      width: 195px;
      color: #000000;
      font-size: 12px;
      margin-top: 18px;
      margin-bottom: 16px;
    }

    .image-section {
      text-align: center;
    }
    button {
      width: 151px;
      height: 38px;
      border-radius: 22px;
      background-color: #e42535;
      font-size: 12px;
    }
  }
}
