.number-card-container {
  width: 100%;
  .skew-container {
    width: 100%;
    background-repeat: no-repeat;
    padding: 20px;
    margin-top: 10px;
    padding-top: 18px;
    background-size: 100% 100%;
    @include media-width-min(510) {
      @include justify-align(center, center);
      flex-wrap: wrap;
      min-height: 220px;
    }
    @include media-width-min(1230) {
      justify-content: space-between;
    }
    .icon {
      width: 50px;
      height: 40px;
      object-fit: contain;
      @include media-width-min(510) {
        margin-right: 17px;
      }
      @include media-width-minmax(1159, 1500) {
        padding-left: 3px;
      }
      @include tablet-lg {
        width: 60px;
      }
    }
    .list-items {
      padding-left: 25px;
      height: 87px;
      @include media-width-min(1230) {
        flex: 1;
      }
      @include justify-align(space-around, center);
      @include media-width-min(716) {
        margin-top: 20px;
      }
      @include media-width-minmax(1159, 1500) {
        padding-left: 12px;
      }
      @include laptop {
        border-right: 1px solid rgb(0, 164, 224);
        &:nth-last-child(2) {
          border: none;
        }
      }
      .title {
        @include justify-align(space-around, center);
        width: 230px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 1px solid rgb(0, 164, 224);
        @include laptop {
          border: none;
        }
        @include media-width-minmax(1159, 1500) {
          @include justify-align(flex-start, center);
          width: 170px;
        }
        h1 {
          font-size: 50px;
          color: $font-color-primary;
          text-shadow: none;
          @include tablet-lg {
            font-size: 55px;
          }
        }
        p {
          width: 110px;
          @include tablet-lg {
            font-size: 18px;
            line-height: 22px;
          }
          @include media-width-minmax(1159, 1500) {
            margin-left: 10px;
          }
        }
      }
    }
    .button-container {
      @include media-width-min(510) {
        flex: 100%;
      }
    }
    .more-button {
      margin-top: 30px;
    }
  }
}
