.frame-carousel-container {
  .slick-slide {
    width: 181px !important;
    height: 180px;
    padding-right: 40px;
    position: relative;
    margin-right: 25px;
    @include tablet {
      margin-right: 40px;
    }
    @include tablet-lg {
      margin-right: 70px;
    }
    @include laptop {
      // margin-right: 130px;
      margin-right: 115px;
    }

    @include desktop-lg {
      margin-right: 120px;
    }
  }

  .slick-track {
    @include flex();
    @include tablet-lg {
      justify-content: flex-start;
      padding-left: 30px;
    }
    @include laptop {
      padding-left: 70px;
    }
  }

  .slick-list {
    padding-left: 16px;
    @include tablet-lg {
      padding-left: 0;
      height: 255px;
    }
  }

  .single-item {
    position: relative;
    left: -55px;
  }

  .frame {
    position: absolute;
    top: -6px;
    left: 0px;
    @include tablet-lg {
      width: 215px;
    }
    @include laptop {
      width: 260px;
    }
  }

  .slick-list {
    padding-top: 15px;
  }

  .parent {
    cursor: pointer;
  }

  .image {
    width: 181px;
    height: 129px;
    border-top-left-radius: 40px;
    border-top-right-radius: 44px;
    border-bottom-right-radius: 70px;
    // border-bottom-left-radius: 31px;
    border-bottom-left-radius: 42px;
    position: relative;
    left: 2px;
    padding: 3px;
    top: 0px;
    @include object-fit(cover);
    @include tablet-lg {
      top: 3px;
      width: 209px;
      height: 144px;
    }
    @include laptop {
      top: 6px;
      width: 248px;
      height: 176px;
    }
  }
  .desc-1 {
    width: 117px;
    position: absolute;
    text-align: left;
    top: 72%;
    left: 52%;
    transform: translateX(-50%);
    font-family: "G-Medium";
    font-size: 10px;
    line-height: 1.36;
    letter-spacing: 0px;
    color: #0f2660;
  }

  .desc-2 {
    @extend .desc-1;
    color: #212121;
    @include tablet-lg {
      font-size: 12px;
      top: 81%;
      left: 55%;
    }
    @include laptop {
      width: 139px;
      font-family: "G-Book";
      font-weight: 900;
      font-size: 13px;
      top: 100%;
      left: 65%;
    }
  }

  @include slick-dots($indicator-color-1);
}

// .frame-carousel-container {
//     //   .slick-list {
//     //     padding: 0 20% 0 0 !important;
//     //   }

//     .slick-slide {
//       padding-right: 25px;
//       position: relative !important;
//     }

//     .frame {
//       position: absolute;
//       top: -6px;
//       left: -20px;
//     }

//     .slick-list {
//       padding-top: 10px;
//     }

//     .image {
//       width: 173px !important;
//       height: 118px !important;
//       border-radius: 54%;
//       border-top-right-radius: 97px;
//       border-bottom-right-radius: 104px;
//     }
//   }
