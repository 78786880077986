.skew-carousel-container {
  // padding-left: 16px;
  padding-top: 0;
  .slick-slide {
    width: 230px !important;
    height: 173px;
    position: relative;
    @include tablet-lg {
      width: 365px !important;
    }

    @include media-width-min(1700) {
      // margin-right: 70px;
      margin-right: 16px;
    }
  }
  .slick-track {
    display: flex;
    margin: 0;
    @include tablet-lg {
      height: 360px;
      padding-left: 30px;
    }
    @include laptop {
      padding-left: 60px;
    }
  }

  .frame {
    position: absolute;
    top: -6px;
    left: 0px;
    @include tablet-lg {
      width: 330px;
    }
  }

  .slick-list {
    padding-top: 15px;
    padding-left: 16px;
  }

  .image {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    position: relative;
    left: -3px;
    top: 10px;
    @include object-fit(cover);
    z-index: 5;
    margin: auto;
    @include tablet-lg {
      width: 91px;
      height: 91px;
      left: 50%;
      top: 30px;
    }
  }
  p {
    width: 117px;
    position: absolute;
    left: 38%;
    transform: translateX(-50%);
    font-family: "G-Medium";

    font-size: 11px;
    line-height: 1.36;
    letter-spacing: 0px;
    color: #212121;
    margin-top: 15px;
    text-align: center;
  }
  .header {
    top: 58px;
    width: 130px;
    font-size: 11px;
    color: $font-color-2;
    left: 84px;
    height: 59px;
    overflow: hidden;
    text-emphasis: initial;
    @include tablet-lg {
      left: 44%;
      font-size: 16px;
      height: 89px;
      width: 203px;
      top: 145px;
    }
  }
  .desc {
    top: 120px;
    font-family: "G-Book";
    font-size: 10px;
    color: #ff8875;
    left: 85px;
    @include tablet-lg {
      left: 44%;
      top: 250px;
      font-size: 14px;
    }
  }
  .single-item {
    position: relative;
    left: -80px;
  }

  .parent {
    width: 166px !important;
  }

  @include slick-dots($indicator-color-1);
}
