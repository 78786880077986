.fish-detection-container {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .fishscan-button {
    z-index: 99999;
  }
  .bounding-box {
    position: absolute;
    // border: 1px solid red;
    // z-index: 9999;
    img {
      z-index: 3;
    }
  }

  .capturedImage {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: black;
    right: 0;
    z-index: 3;
    // transform: rotate(90deg) scale(1.2);
    // object-fit: scale-down;
  }

  video {
    width: 100%;
    // height: 812px;
    // object-fit: cover;
    @include position-transform(fixed, left, 50%, top, 50%, translate(-50%, -50%));
    z-index: 1;
  }

  #test {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
  }

  .fish-name {
    font-size: 18px;
    font-family: "G-Book";
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 50%;
    bottom: -40px;
    color: white;
    opacity: 1;
    filter: brightness(1);
    padding: 12px 28px;
    transform: translateX(-50%);
    // white-space: nowrap;
    border-radius: 17px;
    -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 7px;

    width: 150px;
    text-align: center;
    z-index: 5;
    p {
      font-size: 14px;
    }
    .button-container {
      // margin-top: 6px;
    }
  }

  input[type="file"] {
    display: none;
  }

  .topleft {
    position: absolute;
    left: -2px;
    top: -1px;
  }

  .topright {
    position: absolute;
    right: -2px;
    top: -1px;
  }

  .bottomleft {
    position: absolute;
    bottom: -1px;
    left: -2px;
  }

  .bottomright {
    position: absolute;
    right: -2px;
    bottom: -1px;
  }

  // Capture Button
  .outer-container {
    @include position-transform(fixed, left, 50%, bottom, 17px, translateX(-50%));
    @include justify-align(center, center);
    border: 5px solid white;
    border-radius: 50%;
    width: 69px;
    height: 69px;
    z-index: 2;
    #uploadCaptureBtn {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      border: none;
      background-color: white;
    }
  }

  // Close Icon

  .close-container {
    @include position(absolute, right, 16px, top, 13px);
    @include justify-align(center, center);
    width: 47px;
    height: 47px;
    border-radius: 16px;
    -webkit-backdrop-filter: blur(5.3px);
    backdrop-filter: blur(5.3px);
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 10; //Above camera and Image but below loading
    .close {
      width: 13.2px;
      height: 13.2px;
      @include object-fit(contain);
    }
  }
}
