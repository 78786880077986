.chambers-details-container {
  @include tablet-lg {
    margin-left: 30px;
    margin-right: 30px;
  }
  @include laptop {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include right-cross-header(0) {
    .header-section {
      height: 59px;
      background-color: $header-color-2;
      @include tablet-lg {
        display: none;
      }
    }
  }
  .image-section {
    z-index: 8;
    position: relative;
    margin-bottom: 20px;
    @include tablet-lg {
      width: 50%;
    }
    img {
      height: 277px;
      width: 100%;
      border-radius: 30px;
      margin-top: 9px;
      object-fit: cover;
      @include tablet {
        height: 375px;
      }
      @include tablet-lg {
        height: 375px;
      }
      @include laptop {
        height: 400px;
      }
      @include desktop {
        height: 450px;
      }
      @include desktop-lg {
        height: 500px;
      }
    }
  }

  .desktop-section-1 {
    @include tablet-lg {
      display: flex;
    }
    .desktop-inner-section {
      @include tablet-lg {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
      }

      .audio-section {
        @include tablet-lg {
          margin-top: -15px;
          order: 3;
        }
      }
    }
  }

  .section {
    padding: $padding;
  }

  .button-container {
    margin-top: 20px;
  }

  .section-one {
    .description-18 {
      display: none;
      @include tablet {
        font-size: 20px;
      }
      @include tablet-lg {
        font-size: 24px;
        font-family: "G-Bold";
        display: block;
        margin-bottom: 20px;
      }
      @include laptop {
        font-size: 28px;
      }
    }
    .description-14 {
      @include tablet-lg {
        font-size: 16px;
        line-height: 33px;
      }
      @include laptop {
        font-size: 20px;
      }
    }
  }

  .section-two {
    @include tablet-lg {
      margin-top: 80px;
    }
    h1 {
      @include tablet {
        font-size: 34px;
      }
      @include laptop {
        font-size: 48px;
      }
    }
  }

  .section-three {
    @include tablet {
      @include flex-align(center);
    }

    .description-14 {
      @include tablet {
        padding-right: 100px;
      }
      @include tablet-lg {
        font-size: 16px;
      }
      @include laptop {
        font-size: 20px;
      }
    }

    button {
      @include tablet-lg {
        width: 351px;
      }
    }
  }

  .section-four {
    .content {
      min-height: 151px;
      border-radius: 30px;
      background-color: $font-color-1;
      padding: 15px;
      box-sizing: border-box;
      @include justify-align(space-between, center);
      p {
        width: 203px;
        color: $font-color-2;
        border-left: 4px solid $line-color;
        padding-left: 9px;
      }
      .image {
        width: 77px;
        height: 87px;
        margin: auto;
      }
    }
  }

  // Slick

  @include slick-dots($indicator-color-1);
}
