.landing-screen-container {
    @include position(fixed, top, 0, left, 0);
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.84);
    z-index: 9999;
    min-height: 100%;

    .inner-section {
        overflow-y: scroll;
        height: 100%;
        padding: $padding;
    }
    .close-section {
        text-align: right;
        margin-bottom: 12px;

        @include media-width-max(370) {
            display: none;
        }
    }

    .section-container {
        max-width: 365px;
        min-height: 550px;
        background: white;
        margin: auto;
        border-radius: 14px;
        backdrop-filter: blur(20px);
        padding: 0 15px;
        padding-bottom: 23px;
        @include position-center();
        @include media-width-max(370) {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        @media (max-width: 482px) and (max-height: 658px) {
            max-width: 100%;
            min-height: 100%;
            border-radius: 0;
        }
        .section {
            margin-bottom: 15px;
        }
        .header {
            padding: 35px 0;
            text-align: center;
            color: #02060f;
            opacity: 0.43;
        }
        .icon-section {
            display: flex;
            height: 221px;
            img {
                margin: auto;
            }
        }

        .detail-section {
            width: 100%;
            margin: auto;
            text-align: center;
            h1 {
                font-size: 28px;
                font-family: "G-Bold";
                color: #02060f;
                margin-top: 30px;
                margin-bottom: 10px;
                @include media-width-max(450) {
                    font-size: 24px;
                }
            }
            p {
                font-size: 14px;
                font-family: "G-Book";
                color: #02060f;
            }
        }

        .button-container {
            margin-top: 45px;
        }
    }

    @include slick-dots();
}
