.dolphin-sealion-details-container {
  @include right-cross-header(0);
  // @include top-image-section();
  .header-section {
    @include tablet-lg {
      display: none;
    }
  }
  h1 {
    margin-bottom: 5px;
  }
  .desktop-section-1 {
    @include tablet-lg {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }

    .desktop-inner-section {
      @include tablet-lg {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
      }
    }
  }

  .image-section {
    z-index: 8;
    position: relative;
    margin-bottom: 20px;
    @include tablet-lg {
      width: 50%;
    }
    img {
      height: 277px;
      width: 100%;
      border-radius: 30px;
      margin-top: 9px;
      object-fit: cover;
      @include tablet {
        height: 375px;
      }
      @include tablet-lg {
        height: 375px;
      }
      @include laptop {
        height: 400px;
      }
      @include desktop {
        height: 450px;
      }
      @include desktop-lg {
        height: 500px;
      }
    }
  }

  .section {
    padding: $padding;
  }

  .section-one {
    @include tablet-lg {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .description-18 {
      display: none;
      @include tablet {
        font-size: 20px;
      }
      @include tablet-lg {
        font-size: 24px;
        font-family: "G-Bold";
        display: block;
        margin-bottom: 20px;
      }
      @include desktop-sm {
        font-size: 28px;
      }
    }
    .description-14 {
      @include tablet {
        font-size: 16px;
        margin-top: 12px;
      }
      @include desktop-sm {
        font-size: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }

  .section-two {
    @include margin-tabletlg-laptop;
    @include tablet-lg {
      margin-top: 35px;
    }
    .head-section {
      @include responsive-h1-p-tag();
    }
    .description {
      margin-bottom: 30px;
    }
    .skew {
      @include flex();
      flex-wrap: wrap;
      .big-skew-shape-container {
        margin-right: 15px;
        margin-left: 0;
      }
    }
  }

  .section-three {
    @include tablet-lg {
      margin-top: 70px;
      margin-bottom: 70px;
    }
    .text-section {
      @include margin-tabletlg-laptop;
      @include responsive-h1-tag();
    }
  }

  .section-four {
    border-left: 4px solid $line-color;
    padding-left: 10px;
    margin-top: 25px;

    @include tablet-lg {
      margin: 0 15px;
      margin: 0;
      margin-top: 30px;
      h1 {
        @include tablet {
          font-size: 24px;
        }
        @include desktop-sm {
          font-size: 26px;
        }
      }
      p {
        @include tablet {
          font-size: 16px;
        }
        @include desktop-sm {
          font-size: 18px;
        }
      }
    }
  }
  @include slick-dots($indicator-color-1);
}
