.foot-carousel-container {
  .slick-slide {
    height: 206px;
    position: relative;
  }

  .slick-list {
    padding-top: 15px;
    height: 240px;
    @include tablet-lg {
      height: 278px;
    }
  }

  .slick-track {
    display: flex;
    margin: 0;
    @include tablet-lg {
      padding-left: 38px;
    }
  }

  .frame {
    position: absolute;
    top: -6px;
    left: 0px;
    @include tablet-lg {
      width: 341px;
    }
  }

  p {
    font-family: "G-Medium";
    font-size: 11px;
    line-height: 1.36;
    letter-spacing: 0px;
    color: #212121;
  }

  .parent {
    .time-container {
      width: 41px;
      height: 41px;
      font-size: 10px;
      @include justify-align-direction(center, center, column);
      @include position(absolute, left, 86px, top, 9px);
      border-radius: 50%;
      z-index: 1;
      @include tablet-lg {
        left: 103px;
      }
      .time {
        font-family: "G-Bold";
        color: $font-color-primary;
      }
      .ampm {
        color: $font-color-primary;
      }
    }

    .header {
      font-family: "G-Bold";
      @include position(absolute, left, 88px, top, 73px);
      font-size: 16px;
      line-height: 1.25;
      letter-spacing: 0.01px;
      color: $font-color-4;
      @include tablet-lg {
        top: 83px;
      }
    }
    .desc {
      @include position(absolute, left, 88px, top, 100px);
      font-family: "G-Book";
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: 0px;
      color: $font-color-2;
      width: 177px;
      @include tablet-lg {
        width: 200px;
        top: 108px;
      }
    }
    .button-container {
      @include position(absolute, left, 136px, top, 165px);
      @include tablet-lg {
        left: 168px;
        top: 194px;
      }
      button,
      a {
        @include flex();
        font-size: 12px;
        color: #0d275a;
        .image {
          top: 3px;
        }
      }
    }

    .images {
      @include position(absolute, left, 22px, top, 77px);
      @include tablet-lg {
        left: 27px;
        top: 87px;
      }
    }
  }

  @include slick-dots($indicator-color-1);
}
