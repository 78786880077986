.play-button-container {
  width: 100%;
  height: 100%;
  @include justify-align(center, center);
  @include position(absolute, left, 0, top, 0);
  top: -5px;
  &:hover {
    .pause-button {
      opacity: 1;
    }
  }
  &.show {
    background: rgba(0, 0, 0, 0.4);
  }
  .pause-button {
    opacity: 0;
  }

  .play-button,
  .pause-button {
    border-radius: 0;
    cursor: pointer;
    @include tablet {
      width: 40px;
    }
    @include laptop {
      width: 55px;
    }
  }
}
