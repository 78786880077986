.long-carousel-container {
  //   background-color: white;
  margin: 15px;
  margin-right: 0;
  margin-left: 25px;

  h1 {
    text-shadow: none;
  }

  &.single-item {
    max-width: 100% !important;
    margin: auto;
    padding: 10px;
    .parent-carousel {
      width: 100% !important;
      padding-right: 0;
    }
  }
  .image-section {
    @include tablet-lg {
      grid-row: 1/3;
      padding: 9px;
    }
  }

  .image {
    border-radius: 30px 30px 0 0;
    width: 100%;
    height: 210px;
    @include object-fit(cover);
    @include tablet-lg {
      border-radius: 30px;
      // height: auto;
      height: 300px;
    }
  }

  .parent-carousel {
    // width: 323px !important;
    width: 100% !important;
    padding-right: 20px;
  }

  .section {
    padding: $padding;
  }

  .mobile-only {
    @include tablet-lg {
      display: none;
    }
  }

  .container {
    background-color: white;
    border-radius: 30px;
    position: relative;
    top: -27px;
    .desktop-only {
      @include media-width-max(991) {
        display: none;
      }
    }
    @include tablet-lg {
      display: grid;
      grid-template-columns: 50% 50%;
      top: 0;
      padding-bottom: 30px;
    }

    .section-one {
      padding-bottom: 0;

      h1 {
        margin-bottom: 6px;
        text-transform: capitalize;
        @include tablet {
          width: 535px;
          font-size: 22px;
        }
        @include tablet-lg {
          width: 535px;
          font-size: 24px;
        }
        @include laptop {
          width: 700px;
          font-size: 26px;
        }
      }
      p {
        line-height: 1.6;
        @include tablet-lg {
          font-size: 16px;
        }
        @include desktop-sm {
          font-size: 18px;
        }
      }
    }

    .section-two {
      @include justify-align(space-between, center);
      @include tablet-lg {
        // visibility: hidden;
        align-items: flex-end;
        margin: 0;
        grid-row: 2/2;
      }
      .more-button {
        font-size: 12px;
        @include flex-align(center);
      }
      .left-icon {
        @include justify-align(center, center);
        // background-color: #276464;
        border-radius: 9px;
        color: $font-color-4;
        min-width: 27px;
        height: 27px;
        // padding: 0 12px;
        font-size: 12px;
        font-family: "G-Bold";
        span {
          color: white;
          padding: 3px;
          border-radius: 50%;
          margin-right: 6px;
        }
        @include tablet-lg {
          font-size: 16px;
        }
        img {
          width: 18px;
          margin-right: 8px;
        }
      }
      p {
        color: #e42535;
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: 0.01px;
      }
    }
  }

  .section-three {
    border-left: 4px solid $line-color;
    padding: 0 15px;
    padding-right: 25px;
    margin-left: 12px;
    @include tablet-lg {
      padding: 0 0 0 15px;
      min-height: auto;
      margin-bottom: 0;
      margin-left: 0;
      grid-row: 3/3;
      margin-left: 15px;
    }
    h1 {
      margin-bottom: 8px;
      @include tablet {
        font-size: 18px;
      }
      @include tablet-lg {
        width: 100%;
        font-size: 19px;
      }
    }
    p {
      @include tablet-lg {
        font-size: 16px;
        margin-right: 30px;
      }
      @include desktop-sm {
        font-size: 18px;
      }
    }
  }

  .section-four {
    h1 {
      margin-bottom: 12px;
      @include tablet {
        font-size: 18px;
      }
      @include tablet-lg {
        font-size: 19px;
      }
      @include laptop {
        font-size: 20px;
      }
    }
    @include tablet-lg {
      // grid-row: 2/3;
    }
    .sub-section {
      // @include flex();
      // flex-wrap: wrap;
      // justify-content: space-around;
      // margin-left: -8px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      @include tablet-lg {
        grid-template-columns: repeat(6, 1fr);
      }
      align-items: center;
      .icon-container {
        height: 81%;
        border-right: 1px solid silver;

        @include justify-align(center, center);
        @include media-width-max(991) {
          &:nth-child(3n) {
            border: none;
          }
          &:last-child {
            border: none;
          }
        }
        @include tablet-lg {
          font-size: 14px;
          &:last-child {
            border: none;
          }
        }
      }
      a {
        font-family: "G-Bold";
        font-size: 12px;
        line-height: 1.4;
        text-decoration: underline;
        text-align: center;
        // margin-right: 5px;
        // margin-bottom: 15px;
        color: $font-color-primary;
        transform: scale(0.6);
        display: block;
        @include flex-value(25%);
        @include tablet-lg {
          font-size: 20px;
        }
        .image-icons {
          // width: 30px;
          margin: auto;
          margin-bottom: 10px;
        }
      }
    }
  }

  .mixblend {
    padding: 15px;
    background: white;
    position: absolute;
    top: 76%;
    left: 16px;
    z-index: 9;
    border-radius: 23px;

    .left-icon {
      @include justify-align(center, center);
      // background-color: #276464;
      border-radius: 9px;
      color: white;
      min-width: 27px;
      height: 27px;
      padding: 0 12px;

      @include tablet-lg {
        font-size: 16px;
      }
      img {
        width: 18px;
        margin-right: 8px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  @include slick-dots($font-color-v2-1);
}
