.experts-container {
    padding: $padding;
    padding-top: 0;
    @include tablet-lg {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include laptop {
        padding-left: 60px;
        padding-right: 60px;
    }
    @include right-cross-header(0) {
        .header-section {
            height: 59px;
            background-color: $header-color-2;
            @include tablet-lg {
                display: none;
            }
        }
    }

    .desktop-section {
        @include tablet-lg {
            @include flex();

            .section-one,
            .section-four {
                @include flex-value(1);
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    .section-one,
    .section-two {
        margin-bottom: 20px;
    }

    .section-two {
        @include responsive-h1-tag();
        .description-12 {
            @include tablet {
                font-size: 14px;
            }
            @include tablet-lg {
                font-size: 16px;
            }
            @include desktop-sm {
                font-size: 20px;
            }
        }
    }

    .section-four {
        margin-bottom: 30px;
        margin-top: -20px;
        @include tablet-lg {
            padding-left: 25px;
            padding-top: 5px;
        }
        .description-18 {
            font-family: "G-Bold";
            display: none;
            @include tablet {
                font-size: 20px;
            }
            @include tablet-lg {
                display: block;
                font-size: 24px;
                margin-bottom: 30px;
            }
            @include desktop-sm {
                font-size: 28px;
            }
        }
        h1 {
            @include tablet-lg {
                font-size: 24px;
            }
            @include desktop-sm {
                font-size: 28px;
            }
        }
        .description-12 {
            @include tablet {
                font-size: 14px;
            }

            @include tablet-lg {
                font-size: 16px;
            }
            @include desktop-sm {
                font-size: 20px;
            }
        }
    }

    .audio-section {
        margin-bottom: 36px;
        margin-top: -5px;
    }

    .profile {
        text-align: center;
        .profile-image {
            position: relative;
            min-height: 210px;
            &::before {
                @include before();
                width: 100%;
                height: 32px;
                z-index: 9;
                bottom: 22px;
                border: none;
            }
            img {
                width: 100%;
                margin-bottom: 24px;
                // width: 181px;
            }
        }

        .profile-video {
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 35px;
            video {
                border-radius: 20px;
                width: 100%;
                margin-top: 13px;
            }
            .play-button-container {
                height: 100%;
            }
        }

        .video-description {
            .description-12 {
                overflow: hidden;
                margin-bottom: 80px;
                @include tablet-lg {
                    font-size: 16px;
                    margin-bottom: 42px;
                }
                @include desktop-sm {
                    font-size: 20px;
                }
            }
            .expand {
                display: block;
            }
            .hide {
                display: none;
            }

            .button-section {
                text-align: right;
                padding-right: 16px;
                margin-bottom: 8px;
            }
            button {
                color: $font-color-v2-1;
                font-family: Helvetica;
                font-size: 14px;
                background-color: transparent;
                line-height: 1.43;
                border: none;
                letter-spacing: 0.01px;
                @include tablet {
                    font-size: 18px;
                }
                img {
                    position: relative;
                    left: 10px;
                    top: 2px;
                    transform: rotate(90deg);
                    transition: 0.3 ease-in-out;
                    @include tablet {
                        top: 0;
                        width: 8px;
                    }
                    &.expandArrow {
                        transform: rotate(270deg);
                    }
                }
            }
        }
    }

    .description {
        &.expert-desc {
            width: 72%;
        }
    }

    .activity-list-skew {
        margin: 0;
    }
}
