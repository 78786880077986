.fish-loading-container {
  .loading {
    @include position(absolute, left, 0, top, 0);
    width: 100%;
    height: 100%;
    right: 0;
    @include object-fit(cover);
    z-index: 999999999; //above all
  }
}
