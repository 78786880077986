.lost-chambers-container {
  $h1-bottom: 24px;

  @include left-arrow-header();
  .header-section {
    @include tablet-lg {
      display: none;
    }
  }
  @include top-image-section();

  .desktop-section-1 {
    @include tablet-lg {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
  .section {
    padding: $padding;
  }

  .image-section {
    @include tablet-lg {
      width: 50%;
      height: auto;
    }
  }

  .normal-button {
    min-width: 300px !important;
    width: auto !important;
  }

  .section-one {
    padding-bottom: 0;
    @include tablet-lg {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .description-18 {
      display: none;
      @include tablet {
        font-size: 20px;
      }
      @include tablet-lg {
        font-size: 24px;
        font-family: "G-Bold";
        display: block;
        margin-bottom: 20px;
      }
      @include desktop-sm {
        font-size: 28px;
      }
    }
    .description-14 {
      @include tablet {
        font-size: 16px;
        margin-top: 12px;
      }
      @include desktop-sm {
        font-size: 20px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }

    .button-container {
      @include tablet-lg {
        position: static;
        width: 321px;
        margin: auto;
      }
    }
  }

  .section-two {
    width: 100%;
    height: auto;
    padding: 0 15px;
    @include tablet-lg {
      padding-left: 60px;
      padding-right: 60px;
      margin-top: 80px;
      margin-bottom: 80px;
    }
    @include responsive-h1-p-tag();
  }

  .section-three {
    color: $font-color-2;
    margin-top: 20px;
    padding-bottom: 8px;
    @include responsive-h1-tag();
    @include tablet-lg {
      margin-left: 30px;
      margin-right: 30px;
      .text-button-container {
        margin-bottom: $h1-bottom;
      }
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .audio-section {
    button {
      margin-top: 15px;
      margin-bottom: 5px;
    }

    &.temp-class {
      background: #0f2660;
      border-radius: 23px;
      z-index: 10;
      position: relative;
      padding: 11px;
    }
  }

  .section-four {
    @include tablet-lg {
      display: flex;
      margin-left: 30px;
      margin-right: 30px;
      margin-top: 60px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .desktop-left-section {
    .image {
      display: none;
      @include tablet {
        display: block;
        width: 200px;
        height: 200px;
        border-radius: 20px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }
      }

      @include tablet-lg {
        width: 341px;
        height: 266px;
      }
    }
  }

  .section-five {
    border-radius: 30px;
    background-color: $font-color-1;
    padding: 8px;
    padding-bottom: 24px;
    margin: 15px;
    box-sizing: border-box;
    margin-top: 30px;
    @include tablet-lg {
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;
    }

    @include laptop {
      margin-left: 60px;
      margin-right: 60px;
    }
    @include tablet {
      border-radius: 20px;
      padding-bottom: 15px;
      @include flex();
    }
    .top-section {
      @include flex();
      .image {
        display: block;
        @include tablet {
          display: none;
        }

        img {
          width: 112px;
          height: 105px;
          @include object-fit(cover);
          border-radius: 30px;
        }
      }
      .details {
        @include flex-justify-direction(flex-start, column);
        padding-left: 15px;
        flex: 1;
        @include tablet-lg {
          padding-left: 24px;
        }
        h1 {
          font-family: "G-Medium";
          margin-bottom: 0;
          text-shadow: none;
          margin-bottom: 10px;
          padding-top: 5px;
          @include tablet {
            font-size: 22px;
          }
        }
        p.span {
          font-family: "G-Bold";
          color: $font-color-2;
          @include tablet {
            font-size: 14px;
          }
          span {
            color: #e42535;
          }
        }
      }
    }

    .bottom-section {
      margin-top: 16px;
      @include tablet {
        padding-left: 15px;
        @include flex-justify(space-between);
        flex-direction: column;
        min-height: 51%;
        .button-container {
          text-align: left;
          button {
            font-size: 14px;
            @include tablet-lg {
              font-size: 16px;
            }
          }
        }
      }
      @include tablet-lg {
        padding-left: 24px;
      }
      p {
        @include tablet {
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  .section-eight {
    @include responsive-h1-tag();
    margin-top: 10px;
    .main-text {
      margin-bottom: 0;
      @include tablet-lg {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 60px;
        margin-bottom: $h1-bottom;
      }
      @include laptop {
        margin-left: 60px;
        margin-right: 60px;
      }
    }
  }

  .section-six {
    @include tablet-lg {
      margin-top: 60px;
    }
    .text-section {
      @include responsive-h1-p-tag();
      @include tablet-lg {
        margin-left: 30px;
        margin-right: 30px;
      }
      @include laptop {
        margin-left: 60px;
        margin-right: 60px;
      }
    }
  }

  .walkthrough-container {
    z-index: 9;
  }
}
