.filter-carousel-popup-container {
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.75);
   position: fixed;
   top: 0;
   left: 0;
   z-index: 9;
   display: flex;
   align-items: flex-end;
   .wrapper {
      background-color: #fff;
      border-radius: 30px 30px 0 0;
      min-height: 565px;
      position: relative;
      display: inline-block;
      width: 100%;
      @include tablet {
         min-height: 500px;
         max-width: 500px;
         margin: auto;
         border-radius: 30px;
      }
      .heading-section {
         padding: 0 16px;
         .main-text {
            margin-top: 20px;
            margin-bottom: 10px;
            @include tablet-lg {
               font-size: 32px;
            }
         }
      }
   }

   .list-group {
      padding: 0 16px;
      .list-item {
         display: flex;
         align-items: center;
         margin: 24px 0;
         .icon {
            width: 27px;
            height: 27px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-right: 19px;
         }
      }
   }
   .button-container {
      padding: 15px 0;
      border-top: 1px solid rgb(192, 192, 192);
      .normal-button {
         width: 167px;
         margin: auto;
      }
   }

   .imgclass {
      position: relative;
      right: 0;
      top: 0;
   }

   .description-black-15 {
      display: none;
   }

   .imgcloseicon {
      position: absolute;
      right: 25px;
      top: 24px;
      cursor: pointer;
   }

   // Chnage css 5.30
   .main-list-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 7px 0;
   }

   .list-box {
      width: 33.33333%;
      cursor: pointer;
   }

   input[type="radio"] {
      display: none;
      &:checked {
         ~ svg {
            stroke: #3369cf;
         }
         ~ p {
            color: $font-color-primary;
            font-family: "G-Medium";
            &::after {
               content: "";
               width: 5px;
               height: 5px;
               position: absolute;
               left: 50%;
               bottom: 6px;
               border-radius: 50%;
               background-color: $font-color-primary;
            }
         }
      }
   }

   .list-box {
      display: block;
      text-align: center;
   }
   .list-box p {
      font-size: 11px;
      padding-bottom: 20px;
      position: relative;
   }
   .list-boxany {
      width: 50%;
   }
   .list-boxany p {
      font-size: 11px;
      padding-bottom: 20px;
      position: relative;
      text-align: center;
      cursor: pointer;
   }

   .list-boxdesc {
      width: 25%;
      // margin-left: 60px;
   }
   .list-boxdesc p {
      font-size: 11px;
      padding-bottom: 20px;
      position: relative;
      text-align: center;
      cursor: pointer;
   }

   .list-boxbeg {
      width: 25%;
      // margin-left: 60px;
   }
   .list-boxbeg p {
      font-size: 11px;
      padding-bottom: 20px;
      position: relative;
      text-align: center;
      cursor: pointer;
   }

   .description-black-14 {
      margin: 0px;
      // margin-bottom: 15px;
      font-size: 16px;
   }

   .list-boxbutton {
      width: 50%;
   }

   .active {
      color: #0f2660;
      fill: #002071;
      font-weight: bold;
      position: relative;
   }
   #Divingimg {
      &.active {
         path {
            stroke: #002071;
         }
      }
   }
   .active::after {
      // content: ".";
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      // bottom: 0;
      // font-size: 40px;
      // opacity: 0;
      // border-radius: 50%;
      // opacity: 1;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      font-size: 40px;
      opacity: 0;
      border-radius: 50%;
      opacity: 1;
      width: 5px;
      height: 5px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #0f2660;
   }

   .normal-buttonclick {
      background: transparent !important;
   }

   .doplhinimg {
      position: relative;
      right: 0;
      top: 0;
      text-align: center;
      width: 28px;
      margin: auto;
      height: 32px;
      margin-bottom: 5px;
      display: block;
      border: none;
   }

   .auqainimg {
      position: relative;
      right: 0;
      top: 0;
      text-align: center;
      width: 36px;
      margin: auto;
      height: 33px;
      margin-bottom: 5px;
      display: block;
      border: none;
   }
   .divingimg {
      position: relative;
      right: 0;
      top: 0;
      text-align: center;
      width: 36px;
      margin: auto;
      height: 33px;
      margin-bottom: 5px;
      display: block;
      border: none;
   }

   #bg {
      width: 100%;
      height: 100px;
   }

   .bg1 {
      object-fit: cover;
      object-position: right;
      height: 100%;
      width: 100%;
   }

   .noshadow {
      text-shadow: none;
   }
   .fillc {
      fill: none;
      stroke: #666;
   }

   .file1 {
      fill: none;
      stroke: #666;
      fill-rule: evenodd;
   }
}
