.socialLogin-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: $header-color;
  padding: 3.5rem 10px 2rem 10px;
  position: fixed;
 
  * {
    text-align: center;
  }
  .main-text {
    font-size: 22px;
    padding-bottom: 1rem;
  }
}

.top-section 
{
  .social-btn{
    margin: 1rem auto;
}
.description{
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'G-Book';
  color: #8198BA;
}
.highlight {
  color: $font-color-v2-1;
}
.social-btn{
  display: flex;
  align-items: center;
  justify-content: unset;
  gap: 3px;
  img{
    margin: 0 3px 0px;
  object-fit: contain;
  }
  .facebook-btn {
    font-family: "G-Book";
  font-size: 16px;
  line-height: 2.56;
  font-weight: 600;
  // height: 70px;
  letter-spacing: 0.01px;
  text-align: center;
  color: #fff;
  border: none !important;
  background: none !important;
  border-radius: 30px;
  padding: 0;
  }
  .google-btn{
  font-family: "G-Book";
  font-size: 16px;
  font-weight: 600;
  line-height: 2.56;
  // height: 70px;
  letter-spacing: 0.01px;
  text-align: center;
  color: #fff !important;
  border: none !important;
  background: none !important;
  border-radius: 30px;
  }
}
.social-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
}
  .middle-section {
    font-family: "G-Bold";
    font-size: 18px;
    letter-spacing: 0.01px;
    text-align: center;
    color: #ffffff;
    @include justify-align(center, flex-end);
    padding:10rem 0rem 1rem 0rem;
    span {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 10vw;
        height: 1px;
        opacity: .5;
        background-color: #ffffff;
        right: 36px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        content: "";
        position: absolute;
        width: 10vw;
        height: 1px;
        opacity: .5;
        background-color: #ffffff;
        left: 36px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (min-width: 300px) and (max-width: 667px) {
      padding-top: 2rem;
    }
  }
  .bottom-section{
  .bottom-btn btn{
      margin: 1rem auto;
    }
    .bottom-btn input{
      margin: 1rem auto;
    }
    ::placeholder{
      color: #fff;
    }
    span{
      color: #ffff;
      opacity: .5;
      font-family: 'G-Book';
      font-size: 16px;
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2rem;
    }
  }
  .social-btn img{
    padding: 0 1rem;
  }
  .email-input{
    width: 275px;
    font-family: "G-Book";
    font-size: 16px;
    line-height: 2.56;
    letter-spacing: 0.01px;
    text-align: center;
    color: #fff;
    border: none !important;
    border-radius: 30px;
    background-color: #293B68;
    opacity: 1;
    height: 50px;
    margin: auto;
    display: block;
    text-decoration: none;
    line-height: 47px;
  }