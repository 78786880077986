.shark-lagoon-container {
    .section-three {
        @include tablet-lg {
            margin-top: 80px;
            margin-bottom: 0;
        }
    }

    .section-three {
        border-radius: 30px;
        background-color: $font-color-1;
        padding: 8px;
        padding-bottom: 24px;
        margin: 15px;
        box-sizing: border-box;
        margin-top: 30px;
        @include tablet-lg {
            margin-top: 40px;
            margin-left: 30px;
            margin-right: 30px;
        }

        @include laptop {
            margin-left: 60px;
            margin-right: 60px;
        }
        @include tablet {
            border-radius: 20px;
            padding-bottom: 15px;
            @include flex();
        }
        .top-section {
            @include flex();
            .image {
                display: block;
                @include tablet {
                    display: none;
                }

                img {
                    width: 112px;
                    height: 105px;
                    @include object-fit(cover);
                    border-radius: 30px;
                }
            }
            .details {
                @include flex-justify-direction(flex-start, column);
                padding-left: 15px;
                flex: 1;
                @include tablet-lg {
                    padding-left: 24px;
                }
                h1 {
                    font-family: "G-Medium";
                    margin-bottom: 0;
                    text-shadow: none;
                    margin-bottom: 10px;
                    padding-top: 5px;
                    @include tablet {
                        font-size: 22px;
                    }
                }
                p.span {
                    font-family: "G-Bold";
                    color: $font-color-2;
                    @include tablet {
                        font-size: 14px;
                    }
                    span {
                        color: #e42535;
                    }
                }
            }
        }

        .bottom-section {
            margin-top: 16px;
            @include tablet {
                padding-left: 15px;
                @include flex-justify(space-between);
                flex-direction: column;
                min-height: 49%;
                .button-container {
                    text-align: left;
                    button {
                        font-size: 14px;
                        @include tablet-lg {
                            font-size: 16px;
                        }
                    }
                }
            }
            @include tablet-lg {
                padding-left: 24px;
            }
            p {
                @include tablet {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
    .section-five {
        @include tablet-lg {
            margin-top: 70px;
        }
    }
}
