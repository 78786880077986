.fish-list-container {
  height: 100%;
  background: white;
  position: absolute;
  z-index: 999999;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 434px;
  padding: 30px 20px;
  border-radius: 30px 30px 0 0;
  padding-bottom: 60px;
  .header-section {
    margin-bottom: 38px;
    .description-18-black {
      font-family: "G-Medium";
      font-size: 16px;
      color: #444444;
      margin-top: 7px;
    }
  }

  .close {
    position: absolute;
    top: 18px;
    right: 28px;
  }

  .items-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 26px;
    .items {
      margin-bottom: 10px;
      margin-right: 10px;
      .image {
        height: 114px;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      span {
        font-family: "G-Medium";
        font-size: 13px;
        letter-spacing: 0.01px;
        color: #000000;
        padding-top: 23px;
      }
    }
  }
}
