.video-container {
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  @include tablet-lg {
    border-radius: 60px;
  }
  .slick-slide {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
  }

  .parent-single-video {
    position: relative;
  }

  video {
    width: 100%;
    height: 157px;
    background-color: black;
    border: none;
    border-radius: 20px;

    @include object-fit(cover);
    @include tablet {
      height: 300px;
    }
    @include tablet-lg {
      height: 400px;
    }
    @include laptop {
      height: 460px;
    }
    @include desktop {
      height: 500px;
    }
  }
  .image {
    width: 100%;
    height: 157px;
    @include object-fit(cover);
    border-radius: 20px;
    @include tablet {
      height: 300px;
    }
    @include tablet-lg {
      height: 400px;
    }
    @include laptop {
      height: 460px;
    }
    @include desktop {
      height: 500px;
    }
  }

  img {
    border-radius: 20px;
  }

  p {
    color: $font-color-2;
    font-family: "G-Book";
    font-size: 12px;
    line-height: 1.63;
    letter-spacing: 0px;
    text-align: left;
    padding: 21px 12px;
    @include tablet {
      font-size: 16px;
      padding: 40px 25px;
    }
    @include tablet-lg {
      font-size: 18px;
    }
    @include desktop-sm {
      font-size: 22px;
    }
    b {
      font-family: "G-Bold";
    }
    bi {
      font-family: "G-Bold";
      font-style: italic;
    }
    bu {
      font-family: "G-Bold";
      text-decoration: underline;
    }

    ui {
      font-style: italic;
      text-decoration: underline;
    }

    bui {
      font-family: "G-Bold";
      font-style: italic;
      text-decoration: underline;
    }
  }

  @include slick-dots();
}



