.group-booking-container {
    padding: $padding;
    background-color: $header-color-2;
    z-index: 9;
    padding-top: 0;
    width: 100%;
    margin: auto;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .swal-title {
        margin: 0px;
        font-size: 16px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
        margin-bottom: 28px;
    }
    @include right-cross-header(0) {
        .header-section {
            height: 59px;
            background-color: $header-color-2;
            position: static;
            @include tablet {
                margin-top: 30px;
            }
            p {
                @include tablet {
                    font-size: 40px;
                }
            }
        }
    }

    .sub-section {
        max-width: 619px;
        margin: auto;
        @include tablet {
            p {
                text-align: center;
                font-size: 18px;
                margin-top: 32px;
                &:nth-child(2) {
                    margin-top: 50px;
                }
            }
        }
    }

    label {
        color: white;
    }

    .input-group {
        position: relative;
        margin: 24px 0;
        input {
            width: 100%;
            background: transparent;
            border-radius: 20px;
            border: solid 1px #8a96a7;
            height: 50px;
            padding-left: 16px;
            padding-top: 11px;
            color: white;

            &:focus,
            &:valid {
                + label {
                    top: 3px;
                    font-size: 12px;
                }
            }
        }

        .minmax {
            color: white;
            font-size: 12px;
            font-family: "G-Medium";
            pointer-events: none;
            @include position-transform(absolute, right, 14px, top, 50%, translateY(-50%));
        }

        .calender-icon {
            @include position-transform(absolute, right, 16px, top, 50%, translateY(-50%));
            pointer-events: none;
        }

        label {
            position: absolute;
            left: 16px;
            top: 14px;
            color: #8a96a7;
            font-size: 14px;
            @include transition(0.5s ease-in-out);
        }
    }

    .button-section {
        width: 350px;
        margin: auto;
        padding-bottom: 26px;
        @include media-width-max(767) {
            max-width: 100%;
            // padding-bottom: 25px;
            padding-bottom: 72px;
            background-image: linear-gradient(to bottom, rgba(15, 38, 96, 0), #0f2660 84%);
        }
    }

    // .margin-bottom {
    //     @include media-width-max(767) {
    //         margin-bottom: 80px;
    //     }
    // }

    .custom-input-group {
        position: relative;
        width: 100%;
        margin-left: 15px;
    }

    .calender-container {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.75);
        top: 0;
        left: 0;
        padding: 0 12px;
        @include justify-align(center, center);
        z-index: 1;
        .react-calendar__month-view__days__day--weekend {
            color: #484848;
            font-family: "G-Medium";
            font-size: 12px;
        }
        .react-calendar__tile {
            color: #484848;
            font-family: "G-Medium";
            font-size: 12px;
            padding: 13px;
            margin-bottom: 12px;
        }
        .react-calendar__month-view__weekdays__weekday {
            font-size: 12px;
            color: #0f2660;
            font-family: "G-Medium";
            margin-bottom: 15px;
            abbr {
                text-decoration: none;
            }
        }

        .react-calendar__navigation button {
            font-family: "G-Medium";
            font-size: 14px;
        }

        .close-icon {
            @include position(absolute, right, 32px, top, 32px);
            border: 1px solid white;
            padding: 16px;
            border-radius: 16px;
            cursor: pointer;
        }
    }

    .react-calendar {
        border-radius: 30px;
        overflow: hidden;
    }

    // Phone Input - Do No Touch
    .react-tel-input {
        width: auto;
    }

    form {
        max-width: 619px;
        margin: auto;
    }

    .form-row {
        display: flex;
        .phone-input {
            ::placeholder {
                color: #8a96a7;
            }
        }
    }
    .react-tel-input .flag-dropdown {
        left: 10px !important;
        background-color: transparent !important;
        border: none !important;

        .flag {
            transform: scale(1.5);
            display: none;
            .arrow {
                &.up {
                    border-bottom: none;
                }
            }
        }
    }

    .react-tel-input .country-list {
        margin-top: -2px !important;
    }

    .selected-flag {
        background-color: transparent !important;
        &.open {
            background-color: transparent !important;
        }
    }

    input {
        &.form-control {
            // padding-left: 65px;
            padding-top: 4px;
        }
    }

    .react-tel-input input {
        width: 113px !important;
        @include tablet {
            width: 235px !important;
        }
    }
    .react-tel-input .selected-flag .arrow {
        border-top: 4px solid white;
    }

    // Phone Input END
}
