.main-text {
  @include mainText();
  text-transform: capitalize;
}

.main-text-black-16 {
  @include mainText();
  font-size: 16px;
  color: $font-color-4;
}

.main-text-white-16 {
  @include mainText();
  font-size: 16px;
  color: $font-color-1;
}

.main-text-20 {
  @include mainText();
  font-size: 20px;
  color: $font-color-1;
}

.main-text-black-20 {
  @include mainText();
  font-size: 20px;
  color: $font-color-4;
}

.main-text-black-12 {
  @include mainText();
  font-size: 16px;
  color: $font-color-4;
}

.main-text-14 {
  @include mainText();
  font-size: 14px;
  color: $font-color-1;
}

.main-text-black-14 {
  @include mainText();
  font-size: 14px;
  color: $font-color-4;
}
