@mixin tablet {
  @media (min-width: 767px) {
    transition: 0.5s;
    @content;
  }
}

@mixin tablet-lg {
  @media (min-width: 991px) {
    transition: 0.5s;
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1200px) {
    transition: 0.5s;
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1500px) {
    transition: 0.5s;
    @content;
  }
}

@mixin desktop-sm {
  @media (min-width: 1650px) {
    transition: 0.5s;
    @content;
  }
}
@mixin desktop-lg {
  @media (min-width: 1750px) {
    transition: 0.5s;
    @content;
  }
}
/* Media Query for Max-width */
@mixin media-width-max($media-width) {
  @media all and (max-width: #{$media-width}px) {
    transition: 0.5s;
    @content;
  }
}

/* Media Query for Min-width */
@mixin media-width-min($media-width) {
  @media all and (min-width: #{$media-width}px) {
    transition: 0.5s;
    @content;
  }
}

/* Media Query for Min & Max Width */
@mixin media-width-minmax($media-width-min, $media-width-max) {
  @media all and (min-width: #{$media-width-min}px) and (max-width: #{$media-width-max}px) {
    transition: 0.5s;
    @content;
  }
}

/* Media Query for Max Height */
@mixin media-height-max($media-height-max) {
  @media all and (max-height: #{$media-height-max}px) {
    transition: 0.5s;
    @content;
  }
}

/* Media Query for Min Height */
@mixin media-height-min($media-height-min) {
  @media all and (min-height: #{$media-height-min}px) {
    transition: 0.5s;
    @content;
  }
}

/*======= Flex Functions and Prefix Start */

@mixin flex() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-value($value) {
  -webkit-box-flex: $value;
  -ms-flex: $value;
  flex: $value;
}
/* Flex - Justify , Align */
@mixin justify-align($justify, $align) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

/*  Flex Justify */
@mixin flex-justify($justify) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}

@mixin flex-justify-direction($justify, $direction) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
  -webkit-box-orient: $direction;
  -webkit-box-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin flex-align-direction($align, $direction) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
  -webkit-box-orient: $direction;
  -webkit-box-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

/*  Flex - Align */
@mixin flex-align($align) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

/* Flex Direction */

@mixin flex-direction($direction) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: $direction;
  -webkit-box-orient: $direction;
  -webkit-box-direction: $direction;
  -ms-flex-direction: $direction;
}

@mixin justify-align-direction($justify, $align, $direction) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: $direction;
  -webkit-box-orient: $direction;
  -webkit-box-direction: $direction;
  -ms-flex-direction: $direction;
  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;
  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}

/*======= Flex Functions and Prefix End */

/*======= Position Property Start */
/* Position Absolute Center */
@mixin position-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Position Zero */
@mixin position-zero() {
  position: absolute;
  left: 0;
  top: 0;
}

@mixin position($name, $hor, $horvalue, $ver, $vervalue) {
  position: $name;
  #{$hor}: $horvalue;
  #{$ver}: $vervalue;
}

@mixin position-transform($name, $hor, $horvalue, $ver, $vervalue, $transformValue) {
  position: $name;
  #{$hor}: $horvalue;
  #{$ver}: $vervalue;
  transform: $transformValue;
}
/*======= Position Property End */

/*  Custom Overlay */
@mixin overlay($color, $opacity) {
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color;
    opacity: $opacity;
    z-index: 1;
  }
}

/*  Transition */
@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}

/*  ::before */
@mixin before() {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  @content;
}

@mixin line() {
  @include before();
  width: 4px;
  border-radius: 11px;
  height: 100%;
  left: -19px;
  background-color: $line-color;
  @content;
}

/* Main Text */

@mixin mainText() {
  font-size: 20px;
  font-family: "G-Bold";
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: left;
  color: $font-color-1;
  text-shadow: 1px 1.5px 1px #ce5937;
  margin-bottom: 16px;
  @include tablet {
    text-shadow: 1px 2px 1px #ce5937;
  }
}

@mixin slick-dots($color: #0f2660) {
  .slick-dots {
    li {
      width: auto;
      margin: 0;
    }
    .slick-active {
      button {
        background-color: $color;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: none;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        @include media-width-min(575) {
          width: 16px;
          height: 16px;
        }
      }
    }
    button {
      border: 2px solid $color;
      border-radius: 50%;
      width: 2px;
      height: 2px;
      opacity: 1;
      -webkit-transform: scale(0.4);
      -ms-transform: scale(0.4);
      transform: scale(0.4);
      &::before {
        display: none;
      }
    }
  }
}

// Header with text and right icon

@mixin right-cross-header($top: 30px) {
  .header-section {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    top: $top;
    right: 0;
    z-index: 9;
    height: 55px;
    background: $header-color-2;
    .description-18 {
      font-family: "G-Medium";
      margin: auto;
    }

    img {
      position: relative;
      right: 25px;
      top: 2px;
    }
  }
  @content;
}

// Header with text and left icon

@mixin left-arrow-header() {
  .header-section {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    top: 0;
    right: 0;
    z-index: 9;
    height: 55px;
    background: $header-color-2;

    .description-18 {
      font-family: "G-Medium";
      margin: auto;
    }

    img {
      position: relative;
      left: 16px;
    }
  }
  @content;
}

@mixin top-image-section() {
  .image-section {
    margin-top: 10px;
    height: 318px;
    border-radius: 30px;
    overflow: hidden;
    z-index: 8;
    position: relative;
    background: $header-color-2;
    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }
}

@mixin object-fit($value) {
  -o-object-fit: $value;
  object-fit: $value;
}

// List view For List Page
@mixin list-view {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 370px));
  // justify-content: space-around;
  grid-template: repeat(4, auto) / repeat(4, 1fr);

  @include media-width-max(1520) {
    grid-template: repeat(3, auto) / repeat(3, 1fr);
  }
  @include media-width-max(1125) {
    grid-template: repeat(2, auto) / repeat(2, 1fr);
  }
  @include media-width-max(758) {
    grid-template: repeat(1, auto) / repeat(1, 1fr);
  }
}

// Generic Function for P and H1 Tag Font Sizes
@mixin responsive-h1-p-tag() {
  h1 {
    @include tablet {
      font-size: 34px;
    }
    @include desktop-sm {
      font-size: 48px;
    }
  }
  p {
    @include tablet-lg {
      font-size: 18px;
    }
    @include desktop-sm {
      font-size: 22px;
    }
  }
}

@mixin responsive-h1-tag() {
  h1 {
    @include tablet {
      font-size: 34px;
    }
    @include desktop-sm {
      font-size: 48px;
    }
  }
}

@mixin responsive-p-tag() {
  p {
    @include tablet-lg {
      font-size: 18px;
    }
    @include desktop-sm {
      font-size: 22px;
    }
  }
}

@mixin margin-tabletlg-laptop {
  @include tablet-lg {
    margin-left: 30px;
    margin-right: 30px;
  }

  @include laptop {
    margin-left: 60px;
    margin-right: 60px;
  }
}
