.main-menu-container {
  height: 100%;
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
  left: -100%;
  overflow-y: scroll;
  // background-position-y: -2px;
  background-repeat: no-repeat;
  background-size: cover;
  @include transition(0.5s ease-in-out);
  @include flex-justify-direction(space-between, column);
  .container {
    padding-left: 15px;
    // padding-top: 31px;4
    padding-top: 48px;
    .section {
      @include flex-align(center);
      margin-bottom: 16px;

      .image-section {
        width: 30px;
        height: 53px;
        @include justify-align(center, center);
        margin-bottom: auto;
        &.close-icon {
          position: absolute;
          width: 53px;
          border: 1px solid #f9e256;
          background-color: hsla(0, 0%, 100%, 0.3);
          border-radius: 20px;
        }
      }

      .button-container {
        text-align: left;
      }
      a,
      button {
        font-family: "G-Book";
        color: $font-color-1;
        // margin-left: 18px;
        margin-left: 12px;
        max-width: 287px;
        background-color: transparent;
        border: none;
        text-align: left;
        img {
          display: none;
        }
      }
      .sub-section {
        @include flex-direction(column);
        position: relative;
        top: 15px;
        a,
        button {
          margin-bottom: 12px;
          position: relative;
          font-size: 14px;
          line-height: 21px;
          img {
            display: none;
          }
        }
        .header {
          left: 0;
          font-size: 18px;
          letter-spacing: 0.01px;
          line-height: 28px;
          &.active-class {
            font-family: "G-Bold";
          }
        }
        .sub-sub-section {
          margin-left: 30px;
          margin-bottom: 5px;
          button {
            margin-bottom: 6px;
          }
        }
      }
    }
    .single-element {
      margin-top: 27px;
      margin-bottom: -19px;
      button {
        font-size: 18px;
        @include media-width-max(370) {
          font-size: 18px;
        }
      }
    }
    .section-one {
      position: relative;
      top: -15px;
      .logo {
        width: 163px;
        margin: auto;
      }
    }
  }

  //   Bottom Section Where the Dark Mode and Language is placed
  .bottom-section {
    color: $font-color-1;
    text-align: center;
    padding: 0 16px;
    margin-top: 40px;
    // margin-bottom: 20px;
    margin-bottom: 50px;
    .timing {
      img {
        margin-right: 5px;
      }
      font-family: "G-Medium";
      font-size: 12px;
    }
    .sub-bottom {
      @include flex-justify(center);
      button {
        font-size: 16px;
        background-color: transparent;
        border: none;
        color: $font-color-v2-1;
        font-family: "G-Medium";
        // @include justify-align(center,center);

        .flag {
          width: 24px;
          height: 24px;
          @include position(relative, top, 5px, right, 5px);
        }
      }
    }
  }
}
