@import "./abstract/mixins";
@import "./abstract/variables";
@import "./abstract/global";

@import "../components/Pages/DolphinAndSeaLion/DolphinAndSeaLion.scss";
@import "../components/Pages/CareAndConservation/CareAndConservation.scss";

@import "../components/Pages/PrivacyPolicy/PrivacyPolicy.scss";
@import "../components/Pages/WebsiteTerms/WebsiteTerms.scss";

@import "../components/Pages/DataDeletionInstructions/DataDeletionInstructions.scss";



@import "../components/Pages/AnimalsAndChambers/AnimalsAndChambers.scss";
@import "../components/Pages/AnimalsAndChambers/AnimalChamberList/AnimalChamberList.scss";
@import "../components/Pages/AnimalsAndChambers/ChambersDetails/ChambersDetails.scss";
@import "../components/Pages/AnimalsAndChambers/AnimalsDetails/AnimalsDetails.scss";

@import "../components/PlayButton/PlayButton.scss";
@import "../components/SkewShape/SkewShape.scss";
@import "../components/TextAndButton/TextAndButton.scss";
@import "../components/VideoCard/VideoCard.scss";
@import "../components/NumberCard/NumberCard.scss";
@import "../components/MainText/MainText.scss";
@import "../components/MainMenu/MainMenu.scss";
@import "../components/Header/Header.scss";
@import "../components/Footer/Footer.scss";
@import "../components/FishCameraButton/FishCameraButton.scss";
@import "../components/Description/Description.scss";
@import "../components/Association/Association.scss";
@import "../components/Carousels/CardCarousel/CardCarousel.scss";
@import "../components/Carousels/FrameCarousel/FrameCarousel.scss";
@import "../components/Carousels/LongCarousel/LongCarousel.scss";
@import "../components/Carousels/TvCarousel/TvCarousel.scss";
@import "../components/Pages/Venues/Venues.scss";
@import "../components/Pages/LostChambers/LostChambers.scss";
@import "../components/Button/Button.scss";
@import "../components/Carousels/SmallSkewCarousel/SmallSkewCarousel.scss";
@import "../components/Pages/DolphinAndSeaLion/DolphinSealionDetails/DolphinSealionDetails.scss";
@import "../components/Pages/Home/Home.scss";
@import "../components/Carousels/SquareCarousel/SquareCarousel.scss";
@import "../components/Carousels/FootCarousel/FootCarousel.scss";
@import "../components/Carousels/SkewCarousel/skewCarousel.scss";

@import "../components/FishDetection/FishDetection.scss";
@import "../components/FishDetection/ErrorMessage/ErrorMessage.scss";
@import "../components/FishDetection/Loading/Loading.scss";
@import "../components/FishDetection/FishList/FishList.scss";

@import "../components/IconsSkew/IconsSkew.scss";
@import "../components/LearnAboutFish/LearnAboutFish.scss";
@import "../components/Pages/Experts/Experts.scss";
@import "../components/Pages/Help/Help.scss";
@import "../components/Pages/LandingScreen/LandingScreen.scss";
@import "../components/Loader/Loader.scss";
@import "../components/Carousels/LongCarousel/ConservationStatus/ConservationStatus.scss";
@import "../components/Pages/GroupBooking/GroupBooking.scss";
@import "../components/Pages/SharkLagoon/SharkLagoon.scss";
@import "../components/Pages/Activity/ActivityDetails/ActivityDetails.scss";
@import "../components/Pages/Activity/ActivityList/ActivityList.scss";
@import "../components/Pages/Activity/ActivityList/FilterStatus/FilterStatus.scss";
@import "../components/Pages/Map/Map.scss";
@import "../components/Carousels/CurveCarousel/CurveCarousel.scss";
@import "../components/Audio/Audio.scss";
@import "../components/ReverseSkew/ReverseSkew.scss";
@import "../components/Pages/AnimalListFromQR/AnimalListFromQR.scss";
@import "../components/Pages/AnimalListFromQRWindow/AnimalListFromQRWindow.scss";
@import "../components/FishDetection/Scenario/Scenario.scss";
@import "../components/WalkThrough/WalkThrough.scss";
@import "../components/Journey/Journey.scss";
@import "../components/SocialPage/SocialLogin.scss";
@import '../components/BottomNavbar/BottomNavbar.scss';
@import '../components/MobileFishCameraButton/MobileFishCamera.scss';


@import "../components/Pages/PrivacyPolicy/PrivacyPolicy.scss";
@import "../components/Pages/WebsiteTerms/WebsiteTerms.scss";

@import "../components/Pages/DataDeletionInstructions/DataDeletionInstructions.scss";




.prefix__cls-1,
.prefix__cls-4 {
  stroke-miterlimit: 10;
}
.prefix__cls-1 {
  fill: #e0e0e0;
  stroke: #9d9d9d;
}
.prefix__cls-4 {
  fill: #fff;
}
.prefix__cls-3 {
  fill: #9cc3f2;
}
.prefix__cls-4 {
  stroke: #000;
}
.prefix__cls-5,
.prefix__cls-6 {
  font-size: 12px;
  font-family: ITCAvantGardeStd-Md, ITC Avant Garde Gothic Std;
  font-weight: 500;
}
.prefix__cls-6 {
  font-size: 13px;
}
