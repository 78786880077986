header {
  @include flex();
  position: relative;
  padding: 0 16px;
  height: 50px;
  z-index: 1;
  background-color: transparent;
  @include tablet-lg {
    padding: 0 60px;
  }
  @include laptop {
    height: 130px;
  }
  h1 {
    font-size: 18px;
    font-family: "G-Medium";
    line-height: 36px;
    letter-spacing: 0.01px;
    text-align: center;
    color: #ffffff;
    margin: auto;
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include laptop {
      width: 40px;
      left: 30px;
    }
  }
  .logo {
    margin: auto;
    width: 114px;
    @include laptop {
      width: 235px;
    }
  }
}

// Desktop Header
.desktop-header-container {
  @include justify-align(space-around, center);
  position: relative;
  padding: 0 16px;
  height: 100px;
  z-index: 1;
  background-color: transparent;
  .logo {
    width: 180px;
    @include media-width-min(1100) {
      width: 212px;
    }
  }

  nav {
    display: flex;
    position: relative;
    .active-line {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 135px;
      height: 3px;
      background-color: #f9e256;
      border-radius: 5px;
      transition: 0.3s ease-in-out;
      @include media-width-min(1100) {
        width: 160px;
      }
    }
    a {
      width: 135px;
      font-size: 12px;
      font-family: "G-Book";
      line-height: 21px;
      letter-spacing: 0.01px;
      text-align: center;
      color: #ffffff;
      margin: auto;
      text-align: center;
      height: 35px;

      &.active-link {
        &:nth-child(1) {
          ~ .active-line {
            left: 0;
            @include media-width-min(1100) {
              width: 160px;
            }
          }
        }
        &:nth-child(2) {
          ~ .active-line {
            left: 144px;
            width: 120px;
            @include media-width-min(1100) {
              width: 145px;
              left: 184px;
            }
          }
        }
        &:nth-child(3) {
          ~ .active-line {
            left: 273px;
            width: 130px;
            bottom: -19px;
            @include media-width-min(1100) {
              left: 345px;
              width: 161px;
            }
          }
        }
        &:nth-child(4) {
          ~ .active-line {
            left: 521px;
            width: 159px;
          }
        }
        &:nth-child(5) {
          ~ .active-line {
            left: 706px;
            bottom: -19px;
            width: 137px;
          }
        }
      }
      @include media-width-min(1100) {
        font-size: 14px;
        width: 160px;
        margin-right: 13px;
      }
    }
  }

  .sub-bottom {
    @include flex-justify(center);
    button {
      font-size: 14px;
      background-color: transparent;
      border: none;
      color: white;
      font-family: "G-Medium";
      @include media-width-min(1100) {
        font-size: 16px;
      }
      @include flex-align(center);
      .flag {
        width: 24px;
        height: 24px;
        @include position(relative, top, 1px, right, 5px);
      }

      .image {
        &.white {
          display: none;
        }
      }

      .arrow {
        transform: rotate(90deg);
      }
    }
  }
}
