.animal-list-qr-container {
  .header-section {
    display: none;
    top: 0 !important;
  }
  @include right-cross-header();
  @include tablet {
    margin-top: 30px;
  }
  @include tablet-lg {
    margin-left: 30px;
    margin-right: 30px;
  }
  @include laptop {
    margin-left: 60px;
    margin-right: 60px;
  }

  .skew-shape-items {
    @include media-width-min(759) {
      padding-left: 15px;
    }
  }

  .skew-shape-container {
    @include media-width-max(759) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .description-14 {
    margin-top: 32px;
    @include tablet-lg {
      font-size: 18px;
    }
    @include laptop {
      font-size: 22px;
    }
  }
}
