.walkthrough-container {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    transition: 0.3s ease-out;
    .close {
        position: absolute;
        top: 12px;
        right: 20px;
        z-index: 999;
    }
    .home-page {
        position: absolute;
        right: 85px;
        bottom: 141px;
        max-width: 256px;
        img {
            @include media-height-max(700) {
                bottom: -31px;
                right: -57px;
                width: 85px;
            }
        }
        p {
            line-height: 22px;
            position: relative;
            top: -78px;
            left: 90px;
            padding-right: 93px;
            @include media-height-max(700) {
                font-size: 14px;
                top: -16px;
                left: 129px;
            }
        }
    }
    .home-page-banner {
        position: absolute;
        top: 272px;
        right: 25px;
        max-width: 256px;
        img {
            position: absolute;
            right: 29px;
            @include media-height-max(700){
                width: 70px;
                margin-top: -20px;
            }
        }
        p {
            line-height: 22px;
            margin-top: 68px;
            width: 200px;
            @include media-height-max(700){
                margin-top: 21px;
                font-size: 14px;
            }
        }
    }
    .home-page-line {
        position: absolute;
        bottom: -18px;
        right: -72px;
    }

    .animaldetails-page {
        position: absolute;
        // top: 184px;
        p {
            line-height: 20px;
            width: 264px;
        }
    }
    .animaldetails-page-line {
        position: absolute;
        bottom: -84px;
        right: -72px;
    }

    h1 {
        margin-top: 10px;
        color: white;
        font-size: 18px;
    }
    p {
        color: white;
        font-size: 16px;
        margin-top: 15px;
    }
}
